import { MockOptions } from "../MockOptions.type";
import { SimplifiedMockOptions } from "./SimplifiedMockOptions.type";
import { IDVStatus } from "@module/frankie-client/clients/IDVClient";
import { Applicant } from "@module/common/shared/models/Applicant";
import { DeepPartial } from "@module/common/shared/models/general";
import { Document } from "@module/common/shared/models/Document";

export type SimplifiedIDVFlow =
  // | IDVFlowResult
  // !  ATTENTION: This change above (removing IDVFlowResult) is a breaking change to what was documented.
  // TODO: Need to warn customer (BOQ) about it.
  IDVFlowOptions;
// export type IDVFlowResult = { idvFlowResult: IDVStatus };
export type IDVFlowOptions = {
  idvFlow: {
    result: IDVStatus;
    failGeneratingSession?: boolean;
    capturedIndividualDetails?: DeepPartial<Applicant>;
    capturedDocumentDetails?: DeepPartial<Document>;
  };
};

/**
 * Validate options for IDVFlow
 */
export function validateIdvFlowSimplifiedOptions(options: SimplifiedMockOptions): asserts options is SimplifiedIDVFlow {
  const idvFlow = options.idvFlow;

  if (idvFlow && !idvFlow?.result) {
    throw new Error("idvFlow.result must be provided");
  }
}

export function resolveSimplifiedIDVFlowMockOptions(simplified: SimplifiedMockOptions): Partial<MockOptions> {
  if (!simplified.idvFlow) return {};
  return {
    idvRequests: {
      result: simplified.idvFlow.result,
      failGeneratingSession: simplified.idvFlow.failGeneratingSession ?? false,
      capturedIndividualDetails: simplified.idvFlow.capturedIndividualDetails ?? {},
      capturedDocumentDetails: simplified.idvFlow.capturedDocumentDetails ?? {},
    },
  };
}
