/**
 * Exposes functions that offer a simplified conversion of MockOptions for external usage
 */
import { SimplifiedMockOptions } from "./SimplifiedMockOptions.type";
import {
  resolveSimplifiedPreloadedIndividualMockOptions,
  validatePreloadedIndividualSimplifiedOptions,
} from "./preloadedIndividual";
import { resolveSimplifiedIDVFlowMockOptions, validateIdvFlowSimplifiedOptions } from "./idvFlow";
import { resolveSimplifiedOCRFlowMockOptions, validateOCRFlowSimplifiedOptions } from "./ocrFlow";
import { MockOptions } from "../MockOptions.type";


/**
 * Each simplified mock scenario exposes three elements:
 * 1) Type for its simplified options object, which is merged with the simplified options objects for other features
 * type SimplifiedMockOptions = ScenarioA & ScenarioB & ScenarioC....
 * 2) a function to validate the simplified options object
 * const validateSimplifiedScenarioAMockOptions = (o: SimplifiedMockOptions): asserts o is ScenarioA => {...}
 * 3) a function to resolve the simplified options object into a partial MockOptions object for the feature
 * const resolveSimplifiedScenarioAMockOptions = (o: SimplifiedMockOptions): Partial<MockOptions> => {...}
 * 
 * @param simplifiedOptions simplified options provided by the customer
 * @returns a full MockOptions object, with all the fields resolved
 */
export function resolveSimplifiedMockOptions(simplifiedOptions: SimplifiedMockOptions): MockOptions {
  /* Validate options first */
  validateSimplifiedMockOptions(simplifiedOptions);

  /** Reolve mock options for each feature  */
  // TODO: Consider using a deep merge instead of Object.assign
  // prettier-ignore
  const mockOptions: MockOptions = Object.assign(
    {},
    resolveSimplifiedIDVFlowMockOptions(simplifiedOptions),
    resolveSimplifiedPreloadedIndividualMockOptions(simplifiedOptions),
    resolveSimplifiedOCRFlowMockOptions(simplifiedOptions),
  );

  return mockOptions;
}

function validateSimplifiedMockOptions(
  simplifiedOptions: SimplifiedMockOptions
): asserts simplifiedOptions is SimplifiedMockOptions {
  validateIdvFlowSimplifiedOptions(simplifiedOptions);
  validatePreloadedIndividualSimplifiedOptions(simplifiedOptions);
  validateOCRFlowSimplifiedOptions(simplifiedOptions);
}
