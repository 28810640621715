import { OneSDKError, RecipeConfiguration, merge } from "@module/common";
import { GlobalState, ModeObject, ResolvedRootParameters, SharedDependencies } from "@module/common/types";
import { ConfigurationClient } from "@module/frankie-client/clients/ConfigurationClient";
import { OneSdkRootParameters, RecipeParsed, SdkModes } from "../types";

/**
 *
 * Parses parameters passed to OneSDK constructor and returns a dictionary of GlobalState variables and a list of warnings to be emitted as global events by the top level constructor
 * @param rootParameters All the parameters passed to the OneSDK constructor
 * @returns { globalState: Dictionary, warnings: { level: "info"|"warnings"|"error", message: string, payload: unknown }[] }
 */
export async function parseConfiguration(
  rootParameters: ResolvedRootParameters,
  sharedDependencies: SharedDependencies
): Promise<{ globalState: GlobalState; warnings: Warning[] }> {
  const { frankieClient, globalEventHub } = sharedDependencies;
  // Resolve final recipe name based on optionally provided recipe object
  const warnings: Warning[] = [];
  const recipe = resolveRecipeValue(rootParameters.recipe);
  // use frankie client to fetch existing recipe configuration from API
  const fetchedRecipeConfiguration = await fetchRecipeConfig({ frankieClient, globalEventHub, recipe }, warnings);
  // And merge it with provided config
  const mergedRecipeConfiguration = mergeRecipeConfigurations(recipe, fetchedRecipeConfiguration, warnings);
  // Assemble a "shared state" object, with all the internal resolved configuration and dependencies to be passed to all modules
  // which is the output of this parseConfiguration function
  // The oneSdkInstance object will *later* be injected by the OneSDK initialisation function and also shared with all modules.
  // This gives the modules all capabilities that users also have when accessing the one sdk instance directly
  // We can then from inside the modules provide an extra layer of abstraction and some level of "reflexion" to simplify different use cases.

  const globalState: Omit<GlobalState, "oneSdkInstance"> = {
    mode: rootParameters.mode,
    telemetry: includeTelemetry(rootParameters),
    recipe: mergedRecipeConfiguration,
    frankieClient: sharedDependencies.frankieClient,
    globalEventHub: sharedDependencies.globalEventHub,
    session: sharedDependencies.session,
  };

  return { globalState, warnings };
}
export function resolveParameters(oneSdkRootParameters: OneSdkRootParameters): ResolvedRootParameters {
  type NewModeType = ResolvedRootParameters["mode"];
  const { mode: modeParameter, recipe: recipeParameter } = oneSdkRootParameters;

  const mode: NewModeType = resolveModeObject(modeParameter);
  const recipe = resolveRecipeValue(recipeParameter);
  const session = resolveSession(oneSdkRootParameters.session);
  // Telemetry is on by default. If no value is provided, it will fallback to true.
  const telemetry = oneSdkRootParameters.telemetry ?? true;

  return Object.assign({}, oneSdkRootParameters, {
    mode,
    recipe,
    telemetry,
    session,
  });
}

export function resolveSession(sessionParameter: OneSdkRootParameters["session"]): ResolvedRootParameters["session"] {
  return {
    token: sessionParameter?.token ?? null,
    persist: sessionParameter?.persist ?? false,
    appReference: sessionParameter?.appReference ?? null,
  };
}
export function resolveModeObject(modeParameter: OneSdkRootParameters["mode"]): ModeObject {
  const modeObject: ResolvedRootParameters["mode"] = {
    modeName: SdkModes.PROD,
    is: (mode: SdkModes) => mode === modeObject.modeName,
  };
  if (!modeParameter) return modeObject;
  const providedAsString = typeof modeParameter === "string";
  const ensureObjectValue = providedAsString ? { modeName: modeParameter } : modeParameter;
  return Object.assign(modeObject, ensureObjectValue);
}
export function resolveRecipeValue(recipeParameter: OneSdkRootParameters["recipe"]): RecipeParsed {
  if (!recipeParameter) return { name: "auto" };
  if (typeof recipeParameter === "string") return { name: recipeParameter };
  return {
    ...recipeParameter,
    name: recipeParameter?.name ?? "auto",
  };
}
export function includeTelemetry(oneSdkOptions: ResolvedRootParameters) {
  // Telemetry is on by default. If anything other than a explicit false is passed, enable it.
  return oneSdkOptions.telemetry !== false;
}
function mergeRecipeConfigurations(
  provided: RecipeParsed,
  fetched: RecipeConfiguration,
  warnings: Warning[]
): RecipeParsed {
  // Merge configs by:
  // first) ensuring fetched configuration has recipe name (defaulting it to 'auto')
  // second) keeping fetched name if provided recipe name is 'auto'
  const { name: providedRecipeName, ...providedRecipeObject } = provided;
  const { name: fetchedRecipeName, ...fetchedRecipeObject } = resolveRecipeValue(fetched);
  /* eslint-disable */
  const mergedRecipeConfiguration = merge({}, fetchedRecipeObject, providedRecipeObject, {
    name: providedRecipeName === "auto" ? fetchedRecipeName : providedRecipeName,
  });
  if (providedRecipeName !== "auto" && fetchedRecipeName && providedRecipeName !== fetchedRecipeName) {
    warnings.push({
      level: "error",
      data: new OneSDKError(
        `Recipe name mismatch. Requested '${providedRecipeName}', found '${fetchedRecipeName}'.` +
          "Configuration might be incorrect. Check this event's 'payload' for the resulting configuration object.",
        mergedRecipeConfiguration
      ),
    });
  }
  return mergedRecipeConfiguration;
}
async function fetchRecipeConfig(
  options: Pick<GlobalState, "frankieClient" | "globalEventHub" | "recipe">,
  warnings: Warning[]
): Promise<RecipeConfiguration> {
  warnings.push({
    level: "info",
    data: {
      message: "Fetching recipe configuration",
      payload: { recipeName: options.recipe.name },
    },
  });
  const config = new ConfigurationClient(options.frankieClient, { recipe: options.recipe.name });
  return config.load().catch((error) => {
    warnings.push({
      level: "error",
      data: new OneSDKError("Failed fetching recipe configuration. Using provided configuration only.", error),
    });
    return {};
  });
}

type Warning =
  | { level: "info" | "warning"; data: { message: string; payload?: unknown } }
  | { level: "error"; data: Error };
