import type { EventHub } from '@module/common';
import type { Events } from '@module/device';
import type { OneSdkContext } from '@module/index';
/**
 * This function will simply update the local state of the individual module by calling the search method.
 * @param sharedDependencies options object with all the required dependencies
 */
export async function updateIndividualModuleState(sharedDependencies: {
  localEventHub: EventHub<Events>;
  oneSdkInstance: OneSdkContext;
}) {
  const { localEventHub, oneSdkInstance } = sharedDependencies;
  try {
    // Update local state for the individual before emitting events for results
    const individual = oneSdkInstance.individual();
    await individual.search();
  } catch (e) {
    const errorMessage =
      'Error updating data for the individual. ' +
      "Check error events from module 'individual' for more details." +
      "This isn't related to IDV capture.";
    localEventHub.emit('warning', {
      message: errorMessage,
      payload: e,
    });
    throw e;
  }
}
