import moment from "moment-timezone";
import { TEventPayload } from "./api";
export class TelemetryEvent {
  readonly name: string;
  readonly timestamp: string;
  readonly entityId: string;
  readonly customerReference: string;
  readonly channel: string;
  readonly customerId: string;
  readonly customerChildId: string;
  readonly sessionId: string;
  readonly version: string;
  readonly environment: string;
  readonly browser: { name: string } & Record<string, unknown>;
  readonly device: { model: string; type: string; osName: string } & Record<string, unknown>;
  readonly data: Record<string, unknown>;

  constructor(name: string, event: TEventPayload) {
    this.timestamp = moment.tz("Australia/Sydney").toISOString();
    // for backwards compatibility
    this.name = name;
    Object.assign(this, event);
  }
}
