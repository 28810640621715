import merge from 'lodash.merge';

import type { Events } from '@module/biometrics/events';
import { mkVendorLoader } from '@module/common';
import { defineModule } from '@module/common/modules/defineModule';
import { SdkModes } from '@module/sdk/types';

import { validateConfiguration } from './parseConfiguration';

import type { BiometricsModule } from './definition';

export type { Events };

export default defineModule<BiometricsModule>('biometrics', (
  globalState,
  options,
) => {
  validateConfiguration(globalState);

  const { globalEventHub, recipe: { biometrics }, localEventHub } = globalState;

  // @TODO add validation check
  Object.assign(biometrics, merge(biometrics, options));

  const loadVendorWrapper = mkVendorLoader({
    // If initialising in dummy mode, force the use of the dummy-biometrics wrapper
    // otherwise load the wrapper based on the provider's name
    vendorName: globalState.mode.modeName === SdkModes.DUMMY ? 'dummy-biometrics' : biometrics.provider.name,
    sharedConfiguration: globalState,
    vendorLoader: {
      ocrlabs: () => import(/* webpackChunkName: 'biometrics-ocrlabs' */ './vendors/OcrLabs/index'),
      onfido: () => import(/* webpackChunkName: 'biometrics-onfido' */ './vendors/Onfido/index'),
      incode: () => import(/* webpackChunkName: 'biometrics-incode' */ './vendors/Incode/index'),
      'dummy-biometrics': () => import(/* webpackChunkName: 'biometrics-dummy' */ './vendors/dummy-biometrics/index'),
    },
  });

  // Publicly exposed "mount" function
  const mount = (domElementOrSelector: HTMLElement | string) => {
    // Load and initialise vendor wrapper
    // Which will automatically mount camera feed to HTML element provided as parameter above
    loadVendorWrapper({
      vendorWrapperOptions: () => {
        // Resolve the target HTML Element
        const mountElement =
          typeof domElementOrSelector === 'string'
            ? window.document.querySelector<HTMLElement>(domElementOrSelector)
            : domElementOrSelector;
        if (!mountElement) throw new Error(`DOM Element ${domElementOrSelector} not found.`);
        // Return values required by the wrapper and the vendor loading function
        return {
          eventHub: localEventHub,
          mountElement,
        };
      },
      onSuccess: (_, { vendorName }) => {
        globalEventHub.emit('telemetry', {
          eventName: 'BIOMETRICS:MOUNT',
          data: { vendor: vendorName },
        });
      },
      onError: (error, { vendorName }) => {
        globalEventHub.emit('telemetry', {
          eventName: 'BIOMETRICS:MOUNT:ERROR',
          data: { vendor: vendorName },
          error,
        });
      },
    });
  };

  // Event mappings for telemetry
  localEventHub.on('results', (results) =>
    globalEventHub.emit('telemetry', {
      eventName: 'BIOMETRICS:RESULTS',
      data: {
        checkStatus: results.checkStatus,
        entityId: results.entityId,
        allEmittedFields: Object.keys(results),
      },
    }),
  );
  localEventHub.on('processing', (status) =>
    globalEventHub.emit('telemetry', {
      eventName: 'BIOMETRICS:PROCESSING',
      data: {
        checkStatus: status.checkStatus,
        entityId: status.entityId,
        allEmittedFields: Object.keys(status),
      },
    }),
  );

  return {
    mount,
  };
});

export * from './definition';
