import moment from "moment";

export interface IProfilePayload {
  profileType: string | null;
  kycMethod: string;
  countryAlpha3: string | null;
  dob: string | null;
  checkTypes: string[];
}

export class Profile {
  private kycMethod: string;
  private country = "AUS";
  private profileType: string;
  private config: any;
  private dob: string | null = null;
  private _hadcodedCheckTypes: string[] | null = null;

  constructor(profileType: string | null, kycMethod: string);
  constructor(profileType: string | null, kycMethod: string, countryAlpha3: string | null, dob: string | null, configOrCheckType: { checkTypes: string[] } | any);
  constructor(
    profileType: string | null,
    kycMethod = "electronic",
    countryAlpha3?: string | null,
    dob?: string | null,
    configOrCheckType?: { checkTypes: string[] } | any
  ) {
    this.kycMethod = kycMethod;
    this.profileType = this.initialiseConfigOrCheckType(profileType, configOrCheckType);
    if (countryAlpha3) this.country = countryAlpha3?.toLowerCase();
    if (dob) this.dob = dob;
  }
  initialiseConfigOrCheckType(profileType: string | null, configOrCheckType: any): string {
    let theProfileType = profileType?.toLowerCase();
    if(!configOrCheckType) {
      this._hadcodedCheckTypes = null;
    } else if (Array.isArray(configOrCheckType?.checkTypes)) {
      this._hadcodedCheckTypes = configOrCheckType.checkTypes;
    } else {
      this.config = configOrCheckType;
      const defaultProfileType = this.config.get("default_profile").value() as string;
      theProfileType = theProfileType ?? defaultProfileType?.toLowerCase();
    }
    return theProfileType ?? "none";
  }
  setConfiguration(config: any) {
    this.config = config;
  }
  getConfiguration(): string[] {
    const config = this.config;
    // if there's no configuration for current country, fallback to default.
    // current country may already be default as well
    const isUnder18 = this.dob && moment().diff(this.dob!, "year") < 18;
    // 1 check first for specific country and then for default
    //  a first check if there's configuration for profileType
    //  b if not, decide based on age
    const typeKey = this.profileType;
    const ageKey = isUnder18 ? "under18" : "above18";

    const mkPossibilitiesForCountry = (country) => [
      config.get(country).get(typeKey).value() as string,
      config.get(country).get(ageKey).value() as string,
    ];

    const countryAndFallback = [this.country, "default"];
    const allFourPossibilities = countryAndFallback
      .map(mkPossibilitiesForCountry)
      .flat();

    // out of four possibilities (country type, country age, default type, default age), find the first configuration that exists
    const foundTypesAsString = allFourPossibilities.find(Boolean);
    // to prevent breaking when configuration is not present
    const foundTypesAsArray = foundTypesAsString?.split?.(",") || [];
    return foundTypesAsArray;
  }
  getProfileType(): string {
    return this.profileType;
  }
  get checkTypes(): string[] {
    if (!this.config && !this._hadcodedCheckTypes) return [];
    if (!this.config && !!this._hadcodedCheckTypes) return this._hadcodedCheckTypes;
    // if is manual kyc, replace configured kyc check types with 'manual'
    const isManualKyc = this.kycMethod === "manual";
    const kycTypes = ["id", "id2", "one_plus", "two_plus"];

    let finalTypes: string[] = this.getConfiguration();
    //If finalTypes is profile, we just need to return profile, core will know what need to be run.
    if(finalTypes.includes('profile')) return ['profile'];
    if (isManualKyc) {
      const isNotKyc = (type: string) => !kycTypes.includes(type);
      const nonKycConfiguration = finalTypes.filter(isNotKyc);
      finalTypes = ["manual", ...nonKycConfiguration];
    }

    return finalTypes;
  }

  toJSON(): IProfilePayload {
    return {
      profileType: this.profileType,
      kycMethod: this.kycMethod,
      countryAlpha3: this.country,
      dob: this.dob,
      checkTypes: this.checkTypes // because we can't guarantee configuration serialization, we just output a hardcoded list of chectypes
    };
  }

  static fromJSON(payload: IProfilePayload): Profile {
    const { profileType, kycMethod, countryAlpha3, dob, checkTypes } = payload;

    return new Profile(profileType, kycMethod, countryAlpha3, dob, { checkTypes });
  }
  static default(){
    return new Profile(null, 'electronic')
  }
}