import type { EventHub } from '@module/common';
import { OneSDKError } from '@module/common';
import type { Applicant } from '@module/common/shared/models/Applicant';
import type { Accessors } from '@module/types';

import type { IndividualModule } from '../definition';
type AddReference = IndividualModule['moduleContext']['addReference'];
type MkAddReferenceOptions = {
  applicant$: Accessors<Applicant>;
  eventHub: EventHub;
};

const primaryReferenceKey = 'customer_reference';
export function mkAddReference(deps: MkAddReferenceOptions): AddReference {
  const { applicant$ } = deps;

  const setCustomerReference = (reference: string) => {
    const applicant = applicant$.getValue();
    // If no customer reference existgs, set it.
    if (!applicant.customerReference) {
      applicant.customerReference = reference;
      applicant$.setValue(applicant);
    }
    // if setting the same value, simply ignore it
    else if (applicant.customerReference === reference) return;
    // if trying to change to a different value, throw an error
    // This is due to the whole session potentially being attached to customerReference and changing it would break the session permissions
    else throw new OneSDKError('Customer reference already set. You cannot change it.', {});
  };

  const setGenericReference = (key: string, value: string) => {
    const applicant = applicant$.getValue();
    const secondaryReferences = applicant.additionalReferences;

    if (key === primaryReferenceKey) setCustomerReference(value);
    else if (!secondaryReferences[key]) {
      applicant.additionalReferences[key] = value;
      applicant$.setValue(applicant);
    } else {
      // TODO: We may or may not want to throw an exception here. For now we won't.
      // An attempt to override an existing reference will be ignored and a warning will be emitted.
      // throw new OneSDKError(`Reference '${key}' already set`, { referenceKey: key });
      deps.eventHub.emit('warning', {
        message: `Reference '${key}' already set. This shouldn't happen, but no change was made.`,
      });
    }
  };

  const addReference = (...keyValue: Parameters<AddReference>) => {
    const referenceSetter = keyValue.length < 2 ? setCustomerReference : setGenericReference;
    referenceSetter(...keyValue);
  };

  return addReference as AddReference;
}
