import { Dictionary } from "@types";
import pickBy from "lodash.pickby";

export function deepFreeze<T>(inObj: T): T {
  if (typeof inObj !== "object") return inObj;

  Object.freeze(inObj);

  Object.getOwnPropertyNames(inObj).forEach(function (prop) {
    if (
      // eslint-disable-next-line no-prototype-builtins
      (inObj as unknown as object).hasOwnProperty(prop) &&
      inObj[prop] != null &&
      typeof inObj[prop] === "object" &&
      !Object.isFrozen(inObj[prop])
    ) {
      deepFreeze(inObj[prop]);
    }
  });
  return inObj;
}
export const isNullish = <T>(v: T): v is Exclude<T, null | undefined> => {
  return v === null || typeof v === "undefined";
};
export type Clonable<T = unknown> = { clone(): T };
export type Serialisable = { toJSON(): unknown };
export const isSerialisable = (a: unknown): a is { toJSON: () => unknown } =>
  typeof a === "object" && typeof (a as Serialisable).toJSON !== "undefined";
export const isClonable = <T>(a: unknown): a is Clonable<T> =>
  typeof a === "object" && typeof (a as Clonable).clone !== "undefined";
function serialise(model: unknown) {
  if (isSerialisable(model)) return model.toJSON();
  if (Array.isArray(model)) return model.map(serialise);
  return model;
}
export function naiveObjectComparison(a: unknown, b: unknown): boolean {
  // if both inputs are null, they are equal
  if (isNullish(a) && isNullish(b)) return true;
  // If either a or b are null, but not both, they are not equal
  if (isNullish(a) !== isNullish(b)) return false;
  // If they are both serialisable, compare them as JSON strings
  a = serialise(a);
  b = serialise(b);

  return JSON.stringify(a) === JSON.stringify(b);
}
export function cloneValue<T>(value: T, strict: boolean): T;
export function cloneValue<T>(value: T): T;
export function cloneValue<T>(value: T, strict = false): T {
  // nullish values are not cloned and simply return themselves
  if (isNullish(value)) return value;
  // clonable instances (which contain the clone() method), are cloned and returned
  if (isClonable<T>(value)) return value.clone();
  // arrays are cloned recursively
  if (Array.isArray(value)) return value.map((el) => cloneValue(el)) as unknown as T;
  // plain objects are cloned recursively
  if (typeof value === "object" && value.constructor === Object)
    return Object.getOwnPropertyNames(value).reduce((clone, prop) => {
      clone[prop] = cloneValue(value[prop]);
      return clone;
    }, {} as T);
  // when strict, instances of classes that don't implement the clonable interface will emit an error
  if (strict && typeof value === "object" && value.constructor !== Object) {
    throw new Error(
      `Non clonable instance of class '${value.constructor.name}'. Ensure only clonable objects are passed to 'cloneValue'`
    );
  }
  // return primitives directly, or in non strict mode also return non clonable objects directly
  return value;
}

/**
 * Returns the same object value with undefined (by default) fields removed. Criteria for removing the fields may be defined as a second parameter.
 * 
 * ! This function does not apply to fields recursively, only at root level !
 * 
 * @param objectValue Dictionary Value to be tree shaken
 * @param trimPredicate unknown[] | (v: unknown, k: string) => boolean
 * Predicate to determine if a field should be removed. May be provided as a function or as an array of values to compare against (uses === equality).
 * If function returns true, or the provided array includes the value being tested, the field is removed. The predicate function will take the value of the field and the field name as arguments.
 * @returns The tree shaken value
 */
export function treeShake<T extends Dictionary>(
  objectValue: T,
  trimPredicate: TrimPredicate | TrimValues = [undefined]
): T {
  const predicateFunction = typeof trimPredicate === "function" ? trimPredicate : (v) => trimPredicate.includes(v);

  return <T>pickBy(objectValue ?? {}, (v, key) => !predicateFunction(v, key));
}

type TrimPredicate = (fieldv: unknown, key: string) => boolean;
type TrimValues = unknown[];
