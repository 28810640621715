import { DummyFrankieApiClient } from "../../DummyFrankieApiClient";

export function validateToken(client: DummyFrankieApiClient): DummyFrankieApiClient {
  client.stubResponse(
    { url: "v2/token-validity", method: "get" },
    {
      title: "Successful session token validation",
      status: 200,
    }
  );
  return client;
}
