import { defineModule } from '@module/common/modules/defineModule';
import type { InjectedState } from '@module/common/types';

import type { Step, WorkflowModule } from './definition.types';

export default defineModule<WorkflowModule>('workflow', (shared) => {
  const steps = (shared.recipe.workflow as { steps: Step[] })?.steps ?? null;

  if (!steps) throw new Error('Workflow module requires a workflow configuration');

  const localEventHub = shared.localEventHub;

  const mount = (mountElement: HTMLElement) => {
    // This snippet will execute each step in sequence,
    // waiting for the previous step to complete before executing the next
    const resultsList: unknown[] = [];
    let asyncFailureCause: unknown = null;
    const storeAsyncFailureCause = (cause: unknown) => (asyncFailureCause = cause);
    const chainStepPromises = (
      prevStepPromise: Promise<unknown>,
      stepExecutionObject: { step: Step; executor: () => Promise<unknown> },
      stepIndex: number,
      allExecutors,
    ) => {
      return prevStepPromise.then(async (previousResult) => {
        // -----------------------------------------------------
        // ----> Handle interruption caused by a previous step
        // -----------------------------------------------------
        // If previous result was assynchornous, then listen for failures and store the cause
        if (isAsync(previousResult)) previousResult.promise.catch(storeAsyncFailureCause);
        // If any previous asynchronous step failed and flagged it, then throw the cause of it
        // This will interrupt the chain and skip all subsequent steps
        // That failure could happen at any point in the chain, so we need to check it at each step
        if (asyncFailureCause) throw asyncFailureCause;

        // -----------------------------------------------------
        // ----> Store result of previous step
        // -----------------------------------------------------
        // Since we are storing results for the previous step, stored index is stepIndex - 1
        const previousIndex = stepIndex - 1;
        // If previous result was a promise, then asynchronously schedule storage of result
        if (isAsync(previousResult)) previousResult.promise.then((result) => (resultsList[previousIndex] = result));
          // If previous result was synchronous, then store it immediately.
        // previousIndex will be -1 when evaluating the first step, which should be skipped
        else if (previousIndex >= 0) resultsList[previousIndex] = previousResult;

        // -----------------------------------------------------
        // ----> Execute new step
        // -----------------------------------------------------
        const newResult = await stepExecutionObject.executor();

        // -----------------------------------------------------
        // ----> Handle last executor
        // -----------------------------------------------------
        // If not last in line, then simply resolve the result
        if (stepIndex < allExecutors.length - 1) return newResult;
        // If this was the last executor then
        //  If result is not a promise, store it
        resultsList[stepIndex] = newResult;
        //  If result is a promise, maybe wait for it, or maybe skip this one
        //  TODO: decide what to do if last result is a promise. This might just never happen so for now I'll simply store the promise itself
        return resultsList;
      });
    };
    const workflowPromise: Promise<unknown> = steps
      .map((step) => ({
        step,
        executor: promisifyStep({ step, shared, mountElement }),
      }))
      .reduce(chainStepPromises, Promise.resolve());
    workflowPromise.then((resultsList) => localEventHub.emit('completed', resultsList as unknown[]));
    workflowPromise.catch((error) => localEventHub.emit('failure', error));
  };
  return {
    mount,
  };
});
const isAsync = (result: unknown): result is {
  async: true;
  promise: Promise<unknown>;
} => {
  return typeof result === 'object' && result !== null && 'async' in result && 'promise' in result;
};

/**
 * Interprets a step object and returns a function (step executor) to be used in a promise chain.
 * The step executor will, for example:
 * 1) instantiate a OneSDK Module,
 * 2) attach the promise's "resolve" handler to the completion event of that module (such as "results"),
 * 3) attach the promise's "reject" handler to the failure/error events,
 * 4) start/mount/execute the module.
 * 5) return the promise
 * OBS A) If a module needs to be asynchronously resolved, then the Promise should be resolved immediately to
 * an object with an "async" property set to true and a "promise" property containing another promise.
 * ie: for module:device, the device results are only available after the user has completed the activity. See implementation below.
 * @param options The options object to pass to the module
 * @param options.step The step object to execute
 * @param options.shared The shared state object which includes the recipe configuration and a reference to the OneSDK instance
 * @param options.mountElement The element to mount the step to, which might not be relevant to the step in particular
 * @returns An executor function, which returns a promise to the step result. If step is asynchronous, it resolves to yet another promise
 */
function promisifyStep(options: ExecuteStepAsPromiseOptions): () => Promise<unknown> {
  const { step, shared, mountElement } = options;
  // TODO: we can only run workflow module once, since the instances created below will be persisted
  // We need a counter to add to the instanceName and make them unique per execution

  return () =>
    new Promise<unknown>((resolve, reject) => {
      switch (step.stepId) {
        case 'module:idv': {
          const idvInstance = shared.oneSdkInstance.flow('idv', {
            instanceName: 'workflow_' + step.stepId,
          });
          idvInstance.on('error', reject);
          idvInstance.on('results', resolve);
          idvInstance.mount(mountElement);
        }
          break;
        case 'module:ocr': {
          const ocrInstance = shared.oneSdkInstance.component('ocr', {
            instanceName: 'workflow_' + step.stepId,
          });
          ocrInstance.on('error', reject);
          ocrInstance.on('results', resolve);
          ocrInstance.mount(mountElement);
        }
          break;
        case 'module:biometrics': {
          const biometricsInstance = shared.oneSdkInstance.component('biometrics', {
            instanceName: 'workflow_' + step.stepId,
          });
          biometricsInstance.on('error', reject);
          biometricsInstance.on('results', resolve);
          biometricsInstance.mount(mountElement);
        }
          break;
        case 'module:federation': {
          const federationInstance = shared.oneSdkInstance.component('federation', {
            instanceName: 'workflow_' + step.stepId,
          });
          federationInstance.on('error', reject);
          federationInstance.on('results', resolve);
          federationInstance.start();
        }
          break;
        case 'module:device': {
          const deviceInstance = shared.oneSdkInstance.component('device', {
            activityType: step.activityType,
            instanceName: 'workflow_' + step.stepId,
          });
          deviceInstance.start();
          // This makes this step asynchronous
          resolve({
            async: true,
            promise: new Promise((resolve, reject) => {
              deviceInstance.on('error', reject);
              deviceInstance.on('completed', resolve);
            }),
          });
        }
          break;
        case 'module:form': {
          const formInstance = shared.oneSdkInstance.component('form', {
            instanceName: 'workflow_' + step.stepId,
          });
          formInstance.on('error', reject);
          formInstance.on('results', resolve);
          formInstance.mount(mountElement);
        }
          break;
        case 'action:redirect': {
          // redirect to step.redirectionUrl
          window.location.href = step.redirectUrl;
          resolve(void 0);
        }
          break;
      }
    });
}

type ExecuteStepAsPromiseOptions = {
  step: Step;
  shared: InjectedState;
  mountElement: HTMLElement;
};
