import type { Modules } from '@config';
import type { ModuleDefinition } from '@module/common/types';
import { type InjectedState } from '@module/common/types';
import type { Dictionary } from '@types';

import { OneSDKError } from '../errors/OneSDKError.class';

import type { EventHub } from './eventHub';

/**
 * Helper Closure function that defines a common logic to load and initialise vendor wrappers
 * as well as the events that are emitted in the process.
 *
 * This function (the factory) returns another function (the loader)
 *
 * The factory takes as parameters an object containing:
 * 1. sharedConfiguration: InjectedState, provided to all modules by default
 * 2. vendorName: string,
 * 3. vendorLoader: Function which parameters are
 *      - initialOptions: { sharedConfiguration vendorName }, provided above
 *      - wrapperOptions: options provided later to the loader function
 *      returns the loaded vendor wrapper module containing a function called "initialise"
 *
 * The loader takes as parameters an object containing:
 * 1. vendorWrapperOptions: Function which parameters are
 *      - initialOptions: { sharedConfiguration, vendorName }
 *      returns an object matching the required options passed to the "initialise" function mentioned above
 * 2. "onSuccess" will be called with the Vendor Wrapper Context object as well as other parameters provided above. TBC
 * 3. "onError" will be called with the Error object as well as other parameters provided above. TBC
 *
 * OBS 1: You may interrupt the initialisation chain by throwing an error or returning a rejected promise from any of the provided functions.
 * OBS 2: Both vendorLoader and vendorWrapperOptions accept a shortcut version of their function
 *    - vendorLoader may be provided as a dictionary mapping vendorName to a dynamic import function
 *        ex: {
 *              vendorABC: () => import("relative file path or url to the vendor module exporting a 'initialise' function"),
 *              vendorXYZ: () => import("relative file path or url to the vendor module exporting a 'initialise' function"),
 *            }
 *
 *    - vendorWrapperOptions may be provided as simply an object to be passed to the "initialise" function
 *
 * OBS 3: The vendor wrapper initialise function actually takes two parameters
 *    sharedConfiguration, which is provided by default
 *    vendorWrapperOptions, which is provided in the homonimous parameters of the loader function
 *
 * @param loaderOpts Required options for loading and initialising vendor wrappers
 *
 * @param options.vendorLoader ({ vendorName, sharedConfiguration }) => VendorModule OR Dictionary<VendorName, () => VendorModule
 * @param options.onSuccess What happens after initialising the wrapperContext. Takes the context object as parameter
 * @param options.onError What happens when an error is captured anywhere in the process. Takes the error object as parameter
 *
 * This function is a factory for the actual loader, with the signature
 * 1) First generate the Vendor Wrapper Loader
 * const loadVendor = mkVendorLoader(...options);
 * 2) When its time, call the assynchronous vendorLoader
 * const wrapperContext = await loadVendor(...options);
 * 3) Subsequent calls to loadVendor will returned a cached wrapperContext object.
 * wrapperContext === await loadVendor();
 */
export function mkVendorLoader<WrapperOptions extends Dictionary, WrapperContext, VendorName extends string>(
  loaderOpts: LoaderOptions<VendorName> & VendorLoaderOptions<WrapperOptions, WrapperContext, VendorName>,
): VendorLoader<WrapperOptions, WrapperContext, VendorName> {
  const { vendorLoader, sharedConfiguration, vendorName } = loaderOpts;
  const {
    globalEventHub,
    moduleMeta: { moduleName, instanceName },
  } = sharedConfiguration;
  // Store "telemetry-event-friendly" formatted component name for later use
  const COMPONENT = moduleName.toUpperCase();
  // Cache loaded context
  // let theLoadedContext: WrapperContext;
  // LoadTimeDependencies is the options passed to the wrapper at load time + eventHub + callbacks
  const load = (callbacks: Callbacks<WrapperOptions, WrapperContext, VendorName>) => {
    const { onError = () => void 0, onSuccess = () => void 0, vendorWrapperOptions } = callbacks;

    // vendorWrapperOptions may be provided as either an object or a function that returns an object
    // That object will be merged with the initialOptions.inject object and sent throught the chain of callbacks
    // to be then used to initialise the vendor wrapper as the wrapperOptions object
    const wrapperOpts = isOptionsObject(vendorWrapperOptions) ? vendorWrapperOptions : vendorWrapperOptions(loaderOpts);
    // The vendorLoader may be provided as either a function that returns a Module object which exports an "initialise" function
    // or as a dictionary of VendorName and a function without parameter that dynamically loads and returns said Module object
    // If passed as a function, the vendorLoader will be called with
    // --- 1) the object initialOptions { sharedConfiguration, vendorName, inject }
    // --- 2) the object wrapperOptions, containing the wrapper initialisation options + the extra dependencies (eventHub and others)
    // When importing a module, include a webpackChunkName magic comment to beautify the file output and facilitate debugging
    // import(/* webpackChunkName: 'debug-friendly-name' */ "path/to/wrapper/file.ts")
    // This import is in the format { initialise: VendorWrapperInitialiseFunction }, where
    // VendorWrapperInitialiseFunction = (InjectedState, WrapperOptions) => WrapperContext
    const vendorImporter: VendorWrapperImporter<WrapperOptions, WrapperContext, VendorName> =
      typeof vendorLoader === 'function' ? vendorLoader : vendorLoader[vendorName];

    const { eventHub } = wrapperOpts;

    return (
      Promise.resolve(vendorImporter?.(loaderOpts, wrapperOpts))
        .then((vendorWrapper) => {
          const isDictionaryLoader = typeof vendorLoader === 'object';

          // If resulting loaded VendorModule is null, then it explicitly doesn't have a wrapper to be loaded
          if (vendorWrapper === null) {
            const errorMessage = `Vendor '${vendorName}' doesn't have a wrapper to be loaded. Maybe it's a headless vendor.`;
            throw new Error(errorMessage);
          }
          // If resulting loaded VendorModule is not listed/undefined, throw error
          if (!vendorWrapper) {
            let errorMessage = `Vendor '${vendorName}' not recognised in ${COMPONENT}.`;
            if (isDictionaryLoader) errorMessage += ` Found keys were ${JSON.stringify(Object.keys(vendorLoader))}`;
            throw new Error(errorMessage);
          }
          // If loaded VendorModule doesn't contain an initialise function, throw error
          if (!vendorWrapper.initialise) {
            const errorMessage = `Loaded vendor module '${vendorName}' doesn't contain an 'initialise' function`;
            throw new Error(errorMessage);
          }
          return vendorWrapper;
        })
        // If there were no issues loading the vendor module, initialise it with the provided options
        .then((vendorWrapper) => vendorWrapper.initialise(sharedConfiguration, wrapperOpts))
        // After initialising the vendor wrapper, which will load any required extra vendor dependencies
        // Conclude the process by emitting events and calling the onSuccess or the onError callbacks
        .then((wrapperContext) => {
          eventHub.emit('vendor_sdk_loaded', {
            componentName: moduleName,
            instanceName,
            vendor: loaderOpts.vendorName,
          });
          globalEventHub.emit('telemetry', {
            eventName: `${COMPONENT}:VENDOR_WRAPPER_LOADED`,
            data: {
              instanceName: instanceName,
              vendor: loaderOpts.vendorName,
            },
          });

          onSuccess(wrapperContext, loaderOpts, wrapperOpts);
          // theLoadedContext = wrapperContext;
          // Vendor Wrapper Context is provided to the onSuccess callback and
          // also returned from the function call wrapped in a Promise
          return wrapperContext;
        })
        .catch((errorObject) => {
          const oneSdkError = new OneSDKError(`Vendor sdk '${loaderOpts.vendorName}' failed loading`, {
            errorObject,
            vendor: loaderOpts.vendorName,
          });
          eventHub.emit('vendor_sdk_failed_loading', {
            vendor: loaderOpts.vendorName,
            errorObject,
          });
          eventHub.emit('error', oneSdkError);
          globalEventHub.emit('telemetry', {
            eventName: `${COMPONENT}:VENDOR_FAILED_LOADING`,
            data: {
              instanceName: instanceName,
              vendor: loaderOpts.vendorName,
            },
            error: errorObject,
          });
          onError(errorObject, loaderOpts, wrapperOpts);
          return null;
        })
    );
  };
  // What happens when calling the loader function
  return (callbacks: Callbacks<WrapperOptions, WrapperContext, VendorName>) => {
    // TODO:
    // If vendor wrapper is already cached internally, simply resolve to it without reloading anything else
    // if (theLoadedContext) return Promise.resolve(theLoadedContext);
    // Otherwise trigger the callback chain defined in "load"
    return load(callbacks);
  };
}

// TYPES

// Subset of events required by the function loadAndInitialiseVendor

/**
 * The `VendorLoader` is a function that takes as parameter a `Callbacks` object and returns a Promise of the Vendor `WrapperContext` object
 * When wrapper doesn't have options, than `Callbacks` object is optional, since there's no `vendorWrapperOptions` to be provided
 */
export type VendorLoader<WrapperOptions extends Dictionary, WrapperContext, VendorName extends string> = (
  cs: Callbacks<WrapperOptions, WrapperContext, VendorName>,
) => Promise<WrapperContext>;
/** Type VendorLoader function for a particula ModuleDefinition. Module["wrapperOptions"] must contain "eventHub".
 * TODO: take eventHub from the sharedDependencies instead of the wrapperOptions
 **/
export type ModuleVendorLoader<M extends ModuleDefinition> = VendorLoader<
  M['wrapperOptions'],
  M['wrapperContext'],
  M['vendorName']
>;

export type RequiredEvents = {
  vendor_sdk_loaded: [
    {
      componentName: Modules['moduleName'] | 'individual';
      instanceName: string;
      vendor: string;
    },
  ];
  vendor_sdk_failed_loading: [{ vendor: string; errorObject: unknown }];
  error: [{ message: string; payload: unknown }];
};

type VendorWrapperImport<Opts, Ctx> = Promise<{
  initialise: (b: InjectedState, o: Opts) => Ctx | Promise<Ctx>;
}>;
type VendorWrapperImporter<Opts, Ctx, VendorName extends string> = (
  loaderOpts: LoaderOptions<VendorName>,
  wrapperOpts: Opts,
) => VendorWrapperImport<Opts, Ctx>;

type VendorLoaderOptions<Opts, Ctx, VendorName extends string> = {
  vendorLoader:
    | VendorWrapperImporter<Opts, Ctx, VendorName>
    | Record<VendorName, VendorWrapperImporter<Opts, Ctx, VendorName> | null>;
};

type LoaderOptions<VendorName extends string> = {
  vendorName: VendorName;
  sharedConfiguration: InjectedState;
};

const isOptionsObject = (opts): opts is RequiredDependencies =>
  typeof opts === 'object' && typeof opts.eventHub !== 'undefined';

type RequiredDependencies = {
  eventHub: EventHub<RequiredEvents>;
};
type MergedDependencies<WrapperOptions> = WrapperOptions & RequiredDependencies;
/**
 * Callbacks object containing:
 * 1. vendorWrapperOptions: Function returning a `VendorWrapper` options object or the object itself
 * 2. onSuccess: Function called when the vendor wrapper is successfully initialised
 * 3. onError: Function called when an error is captured anywhere in the process
 */
type Callbacks<WrapperOptions extends Dictionary, Ctx, VendorName extends string> = {
  onSuccess?: (context: Ctx, loaderOpts: LoaderOptions<VendorName>, wrapperOpts: WrapperOptions) => void;
  onError?: (error: Error, loaderOpts: LoaderOptions<VendorName>, wrapperOpts: WrapperOptions) => void;
  vendorWrapperOptions:
    | MergedDependencies<WrapperOptions>
    | ((loaderOpts: LoaderOptions<VendorName>) => MergedDependencies<WrapperOptions>);
};
