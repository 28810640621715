import type { InjectedState } from '@module/common/types';

import type { FormModule } from './definition';

export function validateConfiguration(
  globalState: InjectedState,
): asserts globalState is InjectedState & {
  recipe: FormModule['recipeConfiguration'];
} {
  if (!globalState.recipe.form?.provider?.name) throw new Error(`Form recipe configuration is missing`);

  const provider = globalState.recipe.form.provider;
  const name = provider.name;

  if (name === 'legacy' && !provider.version) throw new Error(`Form recipe configuration is missing 'version'`);
  if (name === 'formio' && !provider.formUrl) throw new Error(`Form recipe configuration is missing 'formUrl'`);
}
