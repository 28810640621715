export enum ENVIRONMENTS {
  development = "development",
  local = "local",
  production = "production",
  uat = "uat",
  demo = "demo",
  qa = "qa",
  staging = "staging",
  staging2 = "staging2",
  staging3 = "staging3",
  staging4 = "staging4",
  staging5 = "staging5",
  staging6 = "staging6",
}
export const env2url = (env: ENVIRONMENTS) => {
  switch (env.toLowerCase()) {
    case "latest":
    case "development":
    case "dev":
      return "https://backend.latest.frankiefinancial.io";
    case "staging":
      return "https://backend.staging.latest.frankiefinancial.io";
    case "staging2":
      return "https://backend.staging2.latest.frankiefinancial.io";
    case "staging3":
      return "https://backend.staging3.latest.frankiefinancial.io";
    case "staging4":
      return "https://backend.staging4.latest.frankiefinancial.io";
    case "staging5":
      return "https://backend.staging5.latest.frankiefinancial.io";
    case "staging6":
      return "https://backend.staging6.latest.frankiefinancial.io";
    case "qa":
      return "https://backend.qa.frankiefinancial.io";
    case "demo":
      return "https://backend.demo.frankiefinancial.io";
    case "uat":
      return "https://backend.kycaml.uat.frankiefinancial.io";
    case "prod":
    case "production":
      return `https://backend.kycaml.frankiefinancial.io`;
    case "local":
      return process.env.PUBLIC_LOCAL_BFF_HOST;
    default:
      return `https://backend.${env}.latest.frankiefinancial.io`;
  }
};
