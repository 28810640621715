import { KeyOf, MaybeTuple, Tuple } from "@types";
import { BehaviorSubject, OperatorFunction, distinctUntilChanged, map, scan, shareReplay } from "rxjs";
import { Accessors, ReadonlyAccessors } from "../types/Accessors";
import { cloneValue, isNullish, naiveObjectComparison } from "./objectUtils";

type ChangeDetector<T> = (a: T, b: T) => boolean;

/**
 * PipeConfig<In, Out, RememberNum> is an options object that simplify the description of how to transform a value
 * It is used across different methods of the class ReactiveStore to generate new values from existing values.
 * Default values for each pipe option may be provided by the method taking PipeConfig as parameter.
 *
 * The pipe options are:
 * - transformer: declare a transformation as a function that takes the old value (type In) and returns the new value (type Out)
 * - changeDetector: defines how to detect changes between two Out values. This will usually default to the function "naiveObjectComparison".
 * - emitArrayOfLatestValues: if this option is provided with a number greater than 1, instead of returning a single value of type Out the transformation will return the N latest values as a N-Tuple of Out
 *
 * The type MaybeTuple<Out, N> is used throughout this file to describe the two possibilities: Out, if N = 1 and N-Tuple of Out otherwise.
 * Usually Accessors<T> type will be defined as Accessors<MaybeTuple<T, RememberNum>>, where the value of RememberNum is passed via PipeConfig
 *
 * keywords: PIPES, TRANSFORMATION, MAYBE-TUPLE, REMEMBER-NUM
 */
type PipeConfig<In, Out = In, RememberNum extends number = 1> = {
  transformer?: (original: In) => Out;
  changeDetector?: (a: Out, b: Out) => boolean;
  emitArrayOfLatestValues?: RememberNum;
};

export class ReactiveStore<T extends object> extends BehaviorSubject<T> {
  private changeDetector: ChangeDetector<T>;
  constructor(initial: T, changeDetector: ChangeDetector<T> = () => true) {
    super(initial);
    this.changeDetector = changeDetector ?? ((a, b) => !naiveObjectComparison(a, b));
  }
  next(newData: T): void {
    if (this.changeDetector(newData, this.getValue())) {
      super.next(newData);
    }
  }
  /**
   * Generates accessors from a property of the root object provided in the constructor.
   *
   * For example:
   *  Root contains object { prop: "prop-value" } as its value.
   *  getRootAccessors("prop") will generate an accessor A whose value is the string "prop-value" and looks like this:
   *  B: Accessor<string> = {
   *      getValue: () => "prop-value",
   *      setValue: (newValue: string) => void,
   *      observable: RXJS.Observable<string>,
   *      property: "prop"
   *  }
   * @param propertyName string the name one of the fields in the root object
   * @returns Accessors<RootObject[PropertyName]>
   */
  getRootAccessors<P extends KeyOf<T>, RememberNum extends number = 1>(propertyName: P) {
    return ReactiveStore.mkPropertyAccessors(
      {
        property: "root-state",
        getValue: () => this.getValue(),
        setValue: (v: T) => this.next(v),
        observable: this,
      },
      {
        propertyName,
      }
    ) as Accessors<MaybeTuple<T[P], RememberNum>, T[P]>;
  }
  /**
   * Generates accessors from a property of the object-value of the provided accessor.
   *
   * For example:
   *  Accessor A contains object { prop: "prop-value" } as its value.
   *  mkPropertyAccessor(A, { propertyName: "prop" }) will generate an accessor B whose value is the string "prop-value" and looks like this:
   *  B: Accessor<string> = {
   *      getValue: () => "prop-value",
   *      setValue: (newValue: string) => void,
   *      observable: RXJS.Observable<string>,
   *      property: "prop"
   *  }
   * @param origin Accessors<Object> source accessors to take the property from
   * @param pipes transformation options, where at least pipes.propertyName is required
   * @returns Accessors<OriginType[PropertyName]>
   */
  static mkPropertyAccessors<OriginType extends object, P extends KeyOf<OriginType>, RemNum extends number = 1>(
    origin: Omit<Accessors<OriginType>, "toReadonly">,
    pipes: PipeConfig<OriginType, OriginType[P], RemNum> & {
      propertyName: P;
    }
  ) {
    const { propertyName, changeDetector = naiveObjectComparison, emitArrayOfLatestValues = 1 } = pipes;

    const readOnlyAccessors = ReactiveStore.mkComputedAccessors(origin, {
      transformer: (original) => (isNullish(original) ? null : original[propertyName]),
      changeDetector,
      emitArrayOfLatestValues,
      propertyName,
    });
    const setValue = (value: OriginType[P]) => {
      const clonedValue = origin.getValue();
      clonedValue[propertyName] = value;
      origin.setValue(clonedValue);
    };
    return {
      ...readOnlyAccessors,
      setValue,
      toReadonly: () => readOnlyAccessors,
    } as Accessors<MaybeTuple<OriginType[P], RemNum>, OriginType[P]>;
  }

  /**
   * Makes a Readonly accessors from an existing accessors object by transforming its internal value according to the `pipes` transformation options
   * @param origin ReadOnlyAccessors<unknown> regular or readonly accessors to take the property from.
   * @param pipes transformation options
   * @returns ReadonlyAccessors
   */
  static mkComputedAccessors<OriginT, TransformedT = OriginT, RememberNum extends number = 1>(
    origin: ReadonlyAccessors<OriginT>,
    pipes?: PipeConfig<OriginT, TransformedT, RememberNum> & { propertyName?: string }
  ) {
    const resolvedPipeConfig = {
      propertyName: pipes?.propertyName ?? `computed(${origin.property})`,
      transformer: pipes?.transformer ?? ((v: OriginT & TransformedT) => v),
      changeDetector: pipes?.changeDetector ?? (naiveObjectComparison as ChangeDetector<TransformedT>),
      emitArrayOfLatestValues: pipes?.emitArrayOfLatestValues ?? (1 as RememberNum),
    };
    const newSubject = mkSubjectFromAccessor(origin, resolvedPipeConfig);

    const getValue = (): MaybeTuple<TransformedT, RememberNum> => {
      const retrievedValue = newSubject.getValue();
      // non strict clone of the original value
      // By making clone non strict it allows us to store things such as native Blob objects,
      // but still reduce the number of mutable objects passed around
      return cloneValue(retrievedValue);
    };
    return {
      property: resolvedPipeConfig.propertyName,
      getValue,
      observable: newSubject,
    } as ReadonlyAccessors<MaybeTuple<TransformedT, RememberNum>>;
  }
}

// Helpers and heavy lifting

/**
 *
 * @param origin Accessors or ReadonlyAccessors to create a RXJS.Subject from
 * @param pipeConfig PipeConfig transformations to be piped on the new Subject
 * @returns new RXJS.Subject
 */
const mkSubjectFromAccessor = <In, Out, RemNum extends number>(
  origin: ReadonlyAccessors<In>,
  pipeConfig: PipeConfig<In, Out, RemNum> & { propertyName: string }
): BehaviorSubject<MaybeTuple<Out, RemNum>> => {
  /** Produce reactive transformed Observable **/

  // Convert PipeConfig into array of RXJS Pipe Operators
  const pipeOperators = mkPipeOperators(pipeConfig);
  // Apply new Pipe Operators to the existing observable (taken from the origin Accessors)
  // Generating a new Observable which reacts to changes to the origin by applying all the configured transformations
  const transformedObservable = origin.observable.pipe(...pipeOperators);

  /** Produce new Initial Value */

  // Number of values to emit by the Subject
  const valueLength: RemNum = pipeConfig.emitArrayOfLatestValues;
  // Generate a initial transformed value, by applying the transformation function to the current value
  const mkInitialValue = (): Out => pipeConfig.transformer(origin.getValue());
  // Make an array of "valueLength" number of values where all elements are initialised as null,
  // except the last element, which is initialised with the newly transformed value.
  // That will cause the "history of N values" for this Subject to be all nulls except the most recent one.
  const mkTupleInitialValue = (): Tuple<Out, RemNum> => {
    const tupleOfValues = Array<Out>(valueLength).fill(null) as Tuple<Out, RemNum>;
    tupleOfValues[valueLength - 1] = mkInitialValue();
    return tupleOfValues;
  };
  // Initial value will either be the latest transformed value or a N-Tuple of nulls ending in the latest transformed value
  const initialValue = pipeConfig.emitArrayOfLatestValues > 1 ? mkTupleInitialValue() : mkInitialValue();

  /** Bring transformed observable and initial value together into a new reactive Subject */

  // Create a new Subject with the initial value defined above
  const newSubject = new BehaviorSubject(initialValue as MaybeTuple<Out, RemNum>);
  // And subscribe it to the transformed observable created initially
  // causing the subject to contain the new transformed initial value and also to react to any changes to the original value
  transformedObservable.subscribe(newSubject);
  return newSubject;
};

// Custom RXJS operator to get latest N values of an observable
// TODO - extract this into rxjsOperators.ts
const getLatest = <Type>(count: number): OperatorFunction<Type, Type[]> => {
  return scan<Type, Type[]>((acc, value) => {
    acc.push(value);
    return acc.splice(-count);
  }, Array(count).fill(null));
};
// Convert PipeConfig object into array of RXJS Operators
type OperatorList<In, Out> = [OperatorFunction<In, unknown>, OperatorFunction<unknown, Out>];
const mkPipeOperators = <In, Out, RememberNum extends number>(
  pipes: PipeConfig<In, Out, RememberNum> & { propertyName: string }
): OperatorList<In, MaybeTuple<Out, RememberNum>> => {
  const { transformer, changeDetector, emitArrayOfLatestValues } = pipes;

  const pipeOperators: unknown[] = [];

  pipeOperators.push(map(transformer), distinctUntilChanged(changeDetector));
  if (emitArrayOfLatestValues > 1) {
    pipeOperators.push(getLatest(emitArrayOfLatestValues));
  }
  pipeOperators.push(shareReplay(1));

  return pipeOperators as OperatorList<In, MaybeTuple<Out, RememberNum>>;
};
