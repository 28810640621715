import { RecipeConfiguration } from "../../common";
import type { FrankieApiClient } from "../FrankieApiClient";
import { BaseClient } from "./BaseClient";

export class ConfigurationClient extends BaseClient {
  constructor(frankie: FrankieApiClient, private options: { recipe: string }) {
    super(frankie);
  }
  async load(): Promise<RecipeConfiguration> {
    const params = {
      item: this.options.recipe,
      itemType: "object",
    };
    return this.frankie
      .get("/onesdk/v1/config", {
        params,
      })
      .then((response) => response.data[this.options.recipe]);
  }
}
