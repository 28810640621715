type AssetType = 'script' | 'style';
type LoadAssetOptions = {
  /** Type of asset. 'script' or 'style' */
  type?: AssetType;
  /** HTML node to append your assets to */
  targetNode?: Node;
};
export function loadAsyncAsset(url: string, options: AssetType | LoadAssetOptions = 'script') {
  const defaults = { type: options as AssetType, targetNode: null as Node | null };
  const { type, targetNode: _targetNode } = typeof options === 'object' ? Object.assign(defaults, options) : defaults;

  const documentObject = _targetNode?.ownerDocument ?? document;
  const targetNode = _targetNode ?? type === 'script' ? documentObject.body : documentObject.head;

  if (type === 'script') return loadScript(url, targetNode);
  if (type === 'style') return loadCSS(url, targetNode);
  return Promise.reject('No valid loader type');
}

/**
 * load script tag asynchronously into a given html node
 * @param url
 * @param target
 */
export function loadScript(url: string, target: Node) {
  return new Promise((resolve, reject) => {
    const script = target.ownerDocument.createElement('script');
    script.src = url;
    script.async = true;
    script.onload = () => resolve(script);
    script.onerror = () => reject(new Error(`Script load error for ${url}`));
    target.appendChild(script);
  });
}

/**
 * load css tag asynchronously into a given html node
 * @param url
 * @param target
 */
export function loadCSS(url: string, target: Node) {
  return new Promise((resolve, reject) => {
    const link = target.ownerDocument.createElement('link');
    link.rel = 'stylesheet';
    link.href = url;
    link.onload = () => resolve(link);
    link.onerror = () => reject(new Error(`CSS load error for ${url}`));
    target.appendChild(link);
  });
}
