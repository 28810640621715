import { v4 as uuid } from "uuid";
import { Scan, TScan } from "@module/common/shared/models/Document";
import { DeepPartial } from "@module/common/shared/models/general";
import merge from "lodash.merge";
import { ScanObject } from "../options/simplified/ocrFlow";

export const resolveScanData = (scanOptions: DeepPartial<Scan>): Scan => {
  const defaultScanObject: ScanObject = {
    file: null,
    fileUploadUuid: null,
    mimeType: "image/jpeg",
    scanCreated: "2022-05-31T03:48:25.000+0000",
    scanId: uuid(),
    scanName: "dl-front.jpg",
    side: "F",
  };

  return Scan.fromJSON(merge(defaultScanObject, scanOptions) as TScan);
};