/**
 * This module exposes functionality for both Authentication (login) and Authorization (data access)
 */

export function getURLParams(): URLSearchParams | null {
  return new URLSearchParams(window.location.search);
}
export function redirectTo(url: URL) {
  window.location.href = url.href;
}
