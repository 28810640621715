import { mkVendorLoader } from '@module/common';
import { defineModule } from '@module/common/modules/defineModule';
import { IDVStatus } from '@module/frankie-client/clients/IDVClient';

import { mkMountMethod } from './actions/mount';
import { validateConfiguration } from './parseConfiguration';

import type { IDVModule } from './definition.types';

export * from './definition.types';

export default defineModule<IDVModule>('idv', (sharedConfiguration) => {
  const {
    localEventHub,
    globalEventHub,
    recipe,
    oneSdkInstance,
  } = sharedConfiguration;
  validateConfiguration(sharedConfiguration);

  /** Load vendor */
  const vendorName = recipe.idv.provider.name;
  const loadVendorWrapper = mkVendorLoader({
    // If initialising in dummy mode, force the use of the dummy-biometrics wrapper
    // otherwise load the wrapper based on the provider's name
    vendorName,
    sharedConfiguration,
    vendorLoader: {
      mastercard: () => import(/* webpackChunkName: 'idv-mastercard' */ './vendors/Mastercard'),
      ocrlabs: () => import(/* webpackChunkName: 'idv-ocrlabs' */ './vendors/OcrLabs'),
      incode: () => import(/* webpackChunkName: 'idv-incode' */ './vendors/Incode'),
      onfido: () => import(/* webpackChunkName: 'idv-onfido' */ './vendors/Onfido'),
    },
  });

  // Event mappings for telemetry
  localEventHub.on('results', (results) =>
    globalEventHub.emit('telemetry', {
      eventName: 'IDV:RESULTS',
      data: {
        checkStatus: results.checkStatus,
        entityId: results.entityId,
        allEmittedFields: Object.keys(results),
      },
    }),
  );
  localEventHub.on('processing', (status) =>
    globalEventHub.emit('telemetry', {
      eventName: 'IDV:PROCESSING',
      data: {
        checkStatus: status.checkStatus,
        entityId: status.entityId,
        allEmittedFields: Object.keys(status),
      },
    }),
  );

  localEventHub.on('input_required', ({ entityId }, status) =>
    globalEventHub.emit('telemetry', {
      eventName: 'IDV:INPUT_REQUIRED',
      data: {
        entityId,
        status,
      },
    }),
  );
  localEventHub.on('detection_complete', () =>
    globalEventHub.emit('telemetry', {
      eventName: 'IDV:DETECTION_SUCCESS',
    }),
  );
  localEventHub.on('detection_failed', (e) =>
    globalEventHub.emit('telemetry', {
      eventName: 'IDV:DETECTION_FAILED',
      data: {
        errorMessage: e.message,
      },
    }),
  );

  const statuses = IDVStatus;
  // TODO: Create reactive state and add public accessors for isLoading$
  const access = () => void 0;
  const isPreloaded = () => {
    const individual = oneSdkInstance.individual();
    if (!individual.isPreloaded()) return false;
    const documents = individual.access('documents').getValue();
    return documents.some((d) => d.idType === 'ATTESTATION');
  };
  const mount = mkMountMethod({
    ...sharedConfiguration,
    eventHub: localEventHub,
    vendorLoader: loadVendorWrapper,
  });

  return {
    access,
    isPreloaded,
    mount,
    statuses,
  };
});
