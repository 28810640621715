import { mkVendorLoader } from '@module/common';
import { defineModule } from '@module/common/modules/defineModule';

import { validateConfiguration } from './validateConfiguration';

import type { FormModule } from './definition';

export default defineModule<FormModule>('form', (globalState) => {
  validateConfiguration(globalState);

  const vendorName = globalState.recipe.form?.provider?.name ?? 'legacy';
  const loadForm = mkVendorLoader({
    sharedConfiguration: globalState,
    vendorName,
    vendorLoader: {
      legacy: () => import(/* webpackChunkName: 'form-legacy' */ './vendors/legacy'),
      formio: () => import(/* webpackChunkName: 'form-formio' */ './vendors/formio'),
      react: () => import(/* webpackChunkName: 'form-react' */ './vendors/react'),
    },
  });
  const mount: FormModule['moduleContext']['mount'] = (mountElement) => {
    const htmlElement: HTMLElement =
      typeof mountElement === 'string' ? document.querySelector(mountElement)! : mountElement;
    return loadForm({
      vendorWrapperOptions: {
        eventHub: globalState.localEventHub,
        mountElement: htmlElement,
      },
    });
  };
  return {
    mount,
    provider: vendorName,
  };
});
