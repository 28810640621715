import { DummyFrankieApiClient } from "../../DummyFrankieApiClient";

export function loadConfiguration(client: DummyFrankieApiClient): DummyFrankieApiClient {
  client.stubResponse(
    { url: "/onesdk/v1/config", method: "get" },
    {
      title: "Load recipe configuration",
      data: {
        auto: {
          name: "default",
          ocr: {
            provider: {
              name: "ocrlabs",
            },
          },
          biometrics: {
            provider: {
              name: "ocrlabs",
            },
          },
        },
      },
    }
  );
  return client;
}
