import { DummyFrankieApiClient } from "../../DummyFrankieApiClient";

export function mockTelemetry(client: DummyFrankieApiClient): DummyFrankieApiClient {
  client.stubResponse({ url: `/data/v1/events`, method: "post" }, (req) => {
    return {
      title: "Telemetry event " + req.data.name,
      status: 201,
    };
  });
  return client;
}
