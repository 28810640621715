// hard coded list of supported vendors which maps directly to a vendor wrapper
export const VendorList = <const>["sardine", "threatmetrix"];
export const ActivityTypeList = <const>[
  "REGISTRATION",
  "LOGIN",
  "CRYPTO_DEPOSIT",
  "CRYPTO_WITHDRAWAL",
  "FIAT_DEPOSIT",
  "FIAT_WITHDRAWAL",
];
export type Vendor = typeof VendorList[number];
export type ActivityType = typeof ActivityTypeList[number];
