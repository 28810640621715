import type { FrankieApiClient } from "../FrankieApiClient";

export class BaseClient {
  constructor(protected frankie: FrankieApiClient) {}

  get reference(): string | null {
    return this.frankie.session.reference ?? null;
  }
  get entityId(): string | null {
    return this.frankie.session.entityId ?? null;
  }
  set entityId(v: string | null) {
    this.frankie.entityId = v;
  }
}
