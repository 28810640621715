export const ID_TYPES = {
  DRIVERS_LICENCE: "DRIVERS_LICENCE",
  PASSPORT: "PASSPORT",
  VISA: "VISA",
  MEDICARE_CARD: "NATIONAL_HEALTH_ID",
  IMMIGRATION: "IMMIGRATION",
  BIRTH_CERT: "BIRTH_CERT",
  CITIZENSHIP: "CITIZENSHIP",
  NAME_CHANGE: "NAME_CHANGE",
  MARRIAGE_CERT: "MARRIAGE_CERT",
  ATTESTATION: "ATTESTATION",
  VEHICLE_REGISTRATION: "VEHICLE_REGISTRATION",
  SELF_IMAGE: "SELF_IMAGE",
  OTHER: "OTHER",
  NATIONAL_ID: "NATIONAL_ID",
  BANK_STATEMENT: "BANK_STATEMENT"
};

export const ID_COUNTRY_CODES = {
  AUSTRALIA: "AUS",
  NEW_ZEALAND: "NZL",
  UNITED_KINGDOM: "GBR",
  INDIA: "IND",
  PHILIPPINES: "PHL",
  CHINA: "CHN",
  HONG_KONG: "HKG",
  INDONESIA: "IDN",
  MALAYSIA: "MYS",
  SPAIN: "ESP",
  SWEDEN: "SWE",
  ITALY: "ITA",
  BRAZIL: "BRA",
  MEXICO: "MEX",
  RUSSIA: "RUS",
  TURKEY: "TUR",
  SINGAPORE: "SGP",
  SOUTH_AFRICA: "ZAF",
  ARGENTINA: "ARG",
  JORDAN: "JOR",
  KUWAIT: "KWT",
  OMAN: "OMN",
  SAUDI_ARABIA: "SAU",
  EGYPT: "EGY",
  ROMANIA: "ROU",
  VIETNAM: "VNM",
  CAMBODIA: "KHM",
  DEFAULT: "DEFAULT"
};

export const ID_COUNTRY_OPTIONS = [
  {
    label: "Australia",
    value: ID_COUNTRY_CODES.AUSTRALIA
  },
  {
    label: "New Zealand",
    value: ID_COUNTRY_CODES.NEW_ZEALAND
  },
  {
    label: "United Kingdom",
    value: ID_COUNTRY_CODES.UNITED_KINGDOM
  }
];
export const ID_SUB_TYPE = {
  [ID_TYPES.NATIONAL_ID]: {
    PAN_CARD: "PAN",
    EPIC_CARD: "EPIC"
  }
};
export const CATEGORY_PRIMARY_PHOTO = "primary_photographic";
export const CATEGORY_PRIMARY_NONPHOTO = "primary_nonphotographic";
export const CATEGORY_SECONDARY = "secondary";

export const ID_OPTIONS = [
  {
    label: "Drivers Licence",
    value: ID_TYPES.DRIVERS_LICENCE,
    available: [
      ID_COUNTRY_CODES.AUSTRALIA,
      ID_COUNTRY_CODES.NEW_ZEALAND,
      ID_COUNTRY_CODES.INDIA
    ]
  },
  {
    label: "Passport",
    value: ID_TYPES.PASSPORT,
    available: [ID_COUNTRY_CODES.DEFAULT]
    // excluded: [ID_COUNTRY_CODES.NEW_ZEALAND]
  },
  {
    label: "Visa",
    value: ID_TYPES.VISA,
    available: [ID_COUNTRY_CODES.AUSTRALIA, ID_COUNTRY_CODES.SINGAPORE]
  },
  {
    label: "Immigration",
    value: ID_TYPES.IMMIGRATION,
    available: [ID_COUNTRY_CODES.AUSTRALIA]
  },
  {
    label: "Birth Certificate",
    value: ID_TYPES.BIRTH_CERT,
    available: [ID_COUNTRY_CODES.AUSTRALIA, ID_COUNTRY_CODES.NEW_ZEALAND]
    // excluded: [ID_COUNTRY_CODES.NEW_ZEALAND]
  },
  {
    label: "Medicare Card",
    value: ID_TYPES.MEDICARE_CARD,
    available: [ID_COUNTRY_CODES.AUSTRALIA]
  },
  {
    label: "Citizenship Certificate",
    value: ID_TYPES.CITIZENSHIP,
    available: [ID_COUNTRY_CODES.AUSTRALIA, ID_COUNTRY_CODES.NEW_ZEALAND]
  },
  {
    label: "Change Of Name Certificate",
    value: ID_TYPES.NAME_CHANGE,
    available: [ID_COUNTRY_CODES.AUSTRALIA]
  },
  {
    label: "Marriage Certificate",
    value: ID_TYPES.MARRIAGE_CERT,
    available: [ID_COUNTRY_CODES.AUSTRALIA]
  },
  {
    label: "Attestation",
    value: ID_TYPES.ATTESTATION,
    available: []
  },
  {
    label: "Motor Vehicle Registration",
    value: ID_TYPES.VEHICLE_REGISTRATION,
    available: [ID_COUNTRY_CODES.NEW_ZEALAND]
  },
  {
    label: "Other",
    value: ID_TYPES.OTHER,
    available: [ID_COUNTRY_CODES.DEFAULT]
  },
  {
    label: "National ID",
    value: ID_TYPES.NATIONAL_ID,
    available: [
      ID_COUNTRY_CODES.PHILIPPINES,
      ID_COUNTRY_CODES.CHINA,
      ID_COUNTRY_CODES.MALAYSIA,
      ID_COUNTRY_CODES.SWEDEN,
      ID_COUNTRY_CODES.ITALY,
      ID_COUNTRY_CODES.RUSSIA,
      ID_COUNTRY_CODES.TURKEY,
      ID_COUNTRY_CODES.JORDAN,
      ID_COUNTRY_CODES.KUWAIT,
      ID_COUNTRY_CODES.OMAN,
      ID_COUNTRY_CODES.SAUDI_ARABIA,
      ID_COUNTRY_CODES.EGYPT,
      ID_COUNTRY_CODES.ROMANIA,
      ID_COUNTRY_CODES.VIETNAM
    ]
  },
  {
    label: "National ID (KTP)",
    value: ID_TYPES.NATIONAL_ID,
    available: [ID_COUNTRY_CODES.INDONESIA]
  },
  {
    label: "National ID (DNI)",
    value: ID_TYPES.NATIONAL_ID,
    available: [ID_COUNTRY_CODES.SPAIN, ID_COUNTRY_CODES.ARGENTINA]
  },
  {
    label: "National ID (CPF)",
    value: ID_TYPES.NATIONAL_ID,
    available: [ID_COUNTRY_CODES.BRAZIL]
  },
  {
    label: "National ID (CURP/RFC)",
    value: ID_TYPES.NATIONAL_ID,
    available: [ID_COUNTRY_CODES.MEXICO]
  },
  // {
  //     label: "NRIC/FIN",
  //     value: ID_TYPES.NATIONAL_ID,
  //     available: [ID_COUNTRY_CODES.SINGAPORE]
  // },
  {
    label: "National ID (Smart ID)",
    value: ID_TYPES.NATIONAL_ID,
    available: [ID_COUNTRY_CODES.SOUTH_AFRICA]
  },
  {
    label: "Hong Kong ID",
    value: ID_TYPES.NATIONAL_ID,
    available: [ID_COUNTRY_CODES.HONG_KONG]
  },
  {
    label: "Voter ID",
    value: ID_TYPES.NATIONAL_ID,
    available: [ID_COUNTRY_CODES.CAMBODIA]
  },
  {
    label: "PAN Card",
    value: ID_TYPES.NATIONAL_ID,
    idSubType: ID_SUB_TYPE.NATIONAL_ID.PAN_CARD,
    available: [ID_COUNTRY_CODES.INDIA]
  },
  {
    label: "Voter ID/EPIC",
    value: ID_TYPES.NATIONAL_ID,
    idSubType: ID_SUB_TYPE.NATIONAL_ID.EPIC_CARD,
    available: [ID_COUNTRY_CODES.INDIA]
  },
  {
    label: "Bank Card",
    value: ID_TYPES.BANK_STATEMENT,
    available: [ID_COUNTRY_CODES.CHINA]
  }
];

export const CONSENT_FLAGS = {
  general: ["general", "docs", "creditheader"],
  flagWhenSplitTheConsent: ['general']
}