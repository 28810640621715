import { parse as htmlParser } from "node-html-parser";
import sanitizeHtml from "sanitize-html";
import { SanitiserOptions } from "../models/Blueprint";

export const mkHtmlSanitiser = Object.assign(
  function (options: SanitiserOptions = {}): (dirty: string) => string {
    const defaultOptions: SanitiserOptions = {
      disallowedTagsMode: "discard",
      ...options,
    };
    return (dirty: string) => sanitizeHtml(dirty, defaultOptions);
  },
  {
    defaults: sanitizeHtml.defaults,
  }
);

export function stripOutScriptTagFromString(content: string) {
  let stripped: string = content;
  stripped = stripped.replace(/<script[^>]*>/gi, "<!-- <removed script>");
  stripped = stripped.replace(/<\/script>/gi, "</removed script> -->");
  return stripped;
  // const html = htmlParser(content, { comment: true, blockTextElements: { script: false } });
  // return html.toString();
}
export function mkAnchorsTargetBlank(content: string) {
  const html = htmlParser(content, { comment: true });
  const as = html.querySelectorAll("a");
  as.forEach((a) => a.setAttribute("target", "_blank"));
  return html.toString();
}
