import { skipFederationLogin, successfulConfirmation, successfulDataFetching } from './federation';
import { mockIDVFlow } from './idvAndBiometrics/idvSubmission';
import { applicantNotFound, loadExistingApplicant, loadSuccessfulCheckResults } from './individual/loadIndividual';
import { submitSuccessfulData, successfulVerification } from './individual/submitData';
import { loadConfiguration } from './initialisation/loadConfiguration';
import { validateToken } from './initialisation/validateToken';
import { submitOCR } from './ocr/ocrSubmission';
import { mockStorage } from './storage/mockStorage';
import { mockTelemetry } from './telemetry';

import type { MockOptions } from './options/MockOptions.type';
import type { DummyFrankieApiClient } from '../DummyFrankieApiClient';

/**
 * applyMocks implements the logic to interpret the MockOptions and apply the appropriate mocks to the DummyFrankieApiClient
 * @param client DummyFrankieApiClient to apply mocks to
 * @param options MockOptions to guide the mocks to be applied
 * @returns DummyFrankieApiClient with mocks applied
 */
export function applyMocks(client: DummyFrankieApiClient, options: MockOptions): DummyFrankieApiClient {
  // TODO: Streamline mocking with a reducer. Read below.
  // 1) Don't test conditions in this function. Let each of the mockScenario functions test their own conditions
  // 2) This function should simply list all the mockScenario functions and apply each of them to the client
  // IE: [...mockScenarioFunctions].reduce((client, mockScenario) => mockScenario(client, options), client);
  // 3) Make sure mockScenario functions use consistent naming that explains what the mockScenario actually is
  // IE: The existing "loadExistingApplicant" is a good name and explains what the mockScenario is doing
  // IE: "successfulDataFetching" is NOT a good name as we have no idea what it refers to

  mockTelemetry(client);
  loadConfiguration(client);
  validateToken(client);

  if (hasPreloadedIndividual(options)) loadExistingApplicant(client, options);
  else applicantNotFound(client);

  submitSuccessfulData(client);
  loadSuccessfulCheckResults(client);
  successfulVerification(client);

  if (hasOcrOptions(options)) submitOCR(client, options);
  if (hasIdvOptions(options)) mockIDVFlow(client, options);

  skipFederationLogin(client);
  successfulDataFetching(client);
  successfulConfirmation(client);
  mockStorage(client);

  return client;
}
const hasPreloadedIndividual = (
  options: MockOptions,
): options is { preloadedIndividual: Exclude<MockOptions['preloadedIndividual'], false> } => {
  return Boolean(options.preloadedIndividual);
};
const hasOcrOptions = (
  options: MockOptions,
): options is Omit<MockOptions, 'ocrRequests'> & { ocrRequests: MockOptions['ocrRequests'] } => {
  return Boolean(options.ocrRequests);
};
const hasIdvOptions = (options: MockOptions): options is MockOptions & { idvRequests: MockOptions['idvRequests'] } => {
  return Boolean(options.idvRequests);
};
