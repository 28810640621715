import { InjectedState } from "@module/common/types";

// TODO: use this function for type checking and asserting recipe configuration
// ie: asserts options is InjectedState & { recipe: { idv: { provider: { name: "mastercard" | "ocrlabs" } } } }
export function validateConfiguration(options: InjectedState) {
  const { recipe } = options;

  if (!recipe.idv) throw new Error("Missing idv configuration");
}

/**
 * TODO: figure out a way for wrappers to get a fully type safe recipe configuration with the function defineWrapper
 * This might require a change in the way we define the recipe configuration to be defined by each of the modules
 * 
 * Proposal:
 * - define the required recipe configuration for a wrapper in the wrapper file itself
 * ``` vendors/wrapperA.ts
 * import { AModule } from "../definition";
 * 
 * type RecipeConfiguration = {
 *   moduleA: { // include the module names as well, since the wrapper might rely on other modules as well
 *    provider: {
 *      name: "vendorA"
 *    }
 *   }
 * }
 * export const initialise = defineWrapper<AModule, RecipeConfiguration>(globalState) => {...} // globalState will have a type safe "recipe"
 * ```
 * - define the required recipe configuration for a module in the module definition
 * ```
 * import { RecipeConfiguration as ModuleARecipe } from "./vendors/wrapperA";
 * import { RecipeConfiguration as ModuleBRecipe } from "./vendors/wrapperB";
 * type RecipeConfiguration = {
 *  commonConfig: boolean;
 * } & ModuleARecipe & ModuleBRecipe 
 * 
 * export type AModule = ModuleDefinition<
 *  module: {
 *    recipe: RecipeConfiguration // New field added to ModuleDefintion
 *    ...
 *  },
 *  vendor: { ... }
 * >;
 * ```
 * 
 * - Then in ModuleTypeSystem merge all the recipe configurations for all modules, resulting in the final "RecipeConfiguration" type, which can be used instead of the current RecipeConfiguration.ts
 */