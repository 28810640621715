import { EventHub, merge } from "@module/common";
import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { SessionContext, SessionMeta } from "../session/SessionContext";
import { FrankieApiClient } from "./FrankieApiClient";

export const URL_PARAM = "([^/?]+)";
export const QUERY_PARAM = "([^&?]+)";
const DEFAULT_DELAY = 0;
const regexKeyMatcher = /^regex:/;
const regexKeyPrefix = "regex:";
export const dummySessionMeta: SessionMeta = {
  customerID: "none",
  customerChildID: "none",
  environment: "https://dummy.com",
  sessionId: "none",
  reference: "dummy-reference",
  entityId: null,
};
const trimUrl = (url: string) => url.replace(/\/$/, "").replace(/^\//, "");
export class DummyFrankieApiClient extends FrankieApiClient {
  protected requestPayloadDictionary: StubResponseDictionary = {};
  protected globalEventHub: EventHub;

  constructor(protected o: { globalEventHub?: EventHub; defaultDelay?: number } = {}) {
    super();
    this.o.defaultDelay = o.defaultDelay ?? DEFAULT_DELAY;
    this.globalEventHub = o.globalEventHub;
    this.instance.defaults.baseURL = "https://dummy.com";
    this.session = new SessionContext(dummySessionMeta);
  }

  stubResponse<TheMethod extends Method | "redirect" | "default">(
    options: { url: string | RegExp; method?: TheMethod },
    responsePayload?: StubResponseDictionary[string][TheMethod]
  ) {
    // if url is passed as regex, remove any leading '/' caracter from its 'source', which is escaped as '\/'
    // '\/' is returned as \\/ from 'source', which needs to be escaped again to be searched and replaced,
    // becoming '\\\/', as below.
    // All url parameters in a regex always need to be made capture groups by surrounding them in "(" ")" to
    // ensure there aren't two patterns that will match the same url
    const url =
      options.url instanceof RegExp ? `${regexKeyPrefix}${options.url.source.replace(/^\\\//, "")}` : options.url;
    const trimmedUrl = trimUrl(url);

    this.requestPayloadDictionary[trimmedUrl] = this.requestPayloadDictionary[trimmedUrl] ?? {};
    const method = options.method ?? ("default" as TheMethod);

    this.requestPayloadDictionary[trimmedUrl][method] = responsePayload;
  }
  findStub<URLPattern extends string, TheMethod extends Method | "redirect">(
    url: URLPattern,
    method: TheMethod
  ): FoundStub<TheMethod> | null {
    const trimmedUrl = trimUrl(url);
    const dictionary = this.requestPayloadDictionary;
    type URLScope = { methods: StubResponseDictionary[string]; urlMatch?: RegExpExecArray | string };

    const findAsRegex = (): false | URLScope => {
      const allMatchedKeys = Object.keys(dictionary)
        .filter((k) => k.match(regexKeyMatcher))
        .map((k) => k.replace(regexKeyMatcher, ""))
        .filter((regexPattern) => {
          // ensure regex pattern is searching the full url by prepending with '^' and appending with '$'
          const regex = new RegExp(`^${regexPattern}$`);
          return Boolean(regex.exec(trimmedUrl));
        });
      if (!allMatchedKeys) return false;
      const longestMatchedKey = allMatchedKeys.sort((a, b) => b.length - a.length).shift();

      const regex = new RegExp(`^${longestMatchedKey}$`);
      const urlMatch = regex.exec(trimmedUrl);
      return {
        methods: dictionary[`${regexKeyPrefix}${longestMatchedKey}`],
        urlMatch,
      };
    };
    const findAsKey = (): false | URLScope => {
      if (!dictionary[trimmedUrl]) return false;
      return { methods: dictionary[trimmedUrl], urlMatch: trimmedUrl };
    };

    const findStoredStub = (): FoundStub<Method | "redirect"> | null => {
      const payloadsForUrl = findAsKey() || findAsRegex();
      if (!payloadsForUrl) return null;

      const { methods, urlMatch } = payloadsForUrl;
      const payloadForMethod = methods?.[method as Method | "default" | "redirect"];
      const defaultPayload = methods?.default;
      // If url wasn't even found
      if (!payloadsForUrl) return null;
      // If method wasn't found and there isnt a default
      if (!payloadForMethod && !defaultPayload) return null;
      // If method wasn't found, but default stub was, return default
      if (!payloadForMethod && defaultPayload) return { stub: defaultPayload };
      // Otherwise stub for specific method was found, return it
      return { stub: payloadForMethod, urlMatch };
    };

    return findStoredStub() as FoundStub<TheMethod>;
  }
  getStubPayload(config: Partial<AxiosRequestConfig>): AxiosResponse & { delay?: number } {
    const { url, method } = config as { url: string; method: Method };
    const defaultResponse: AxiosResponse & { isMockResponse: true } = {
      data: {},
      status: 200,
      statusText: "OK",
      headers: {},
      config,
      isMockResponse: true,
    };

    const mergeWithDefaults = (foundStub: FoundStub<Method>) => {
      const { stub, urlMatch } = foundStub;
      // provide configuration and matched url (either regex match results or trimmed url string)
      const response = typeof stub === "function" ? stub({ ...config, urlMatch }) : stub;
      return merge({}, defaultResponse, response);
    };
    const storedStub = this.findStub(url, method) ?? { stub: { defaultStub: true } };
    const mergedResponse = mergeWithDefaults(storedStub);

    return mergedResponse;
  }
  preventUncaughtErrors(url: string, config: AxiosRequestConfig | undefined, method: Method, data?: unknown) {
    try {
      const { delay = this.o.defaultDelay, ...payload } = this.getStubPayload({ ...config, url, method, data });
      return this.delayResponse(payload, delay);
    } catch (e) {
      e.message = `Error mocking request: "${e.message}"`;
      throw e;
    }
  }
  async get<T = unknown>(url: string, config?: AxiosRequestConfig | undefined): Promise<AxiosResponse<T>> {
    return this.preventUncaughtErrors(url, config, "get");
  }
  async delete<T = unknown>(url: string, config?: AxiosRequestConfig | undefined): Promise<AxiosResponse<T>> {
    return this.preventUncaughtErrors(url, config, "delete");
  }
  async post<T = unknown>(
    url: string,
    data?: unknown,
    config?: AxiosRequestConfig | undefined
  ): Promise<AxiosResponse<T>> {
    return this.preventUncaughtErrors(url, config, "post", data);
  }
  async put<T = unknown>(
    url: string,
    data?: unknown,
    config?: AxiosRequestConfig | undefined
  ): Promise<AxiosResponse<T>> {
    return this.preventUncaughtErrors(url, config, "put", data);
  }
  redirect(url: URL) {
    const { stub } = this.findStub(url.hostname, "redirect");
    const resultingURL = stub(url);
    if (resultingURL) window.location.href = resultingURL.href;
  }

  private async delayResponse<T = unknown>(
    payload: AxiosResponse<T> & { title?: string },
    delay: number
  ): Promise<AxiosResponse<T>> {
    let groupTitle = "Executing mock request to";
    const requestSignature = `${payload.config.method.toUpperCase()} ${payload.config.url}`;

    if (payload.title) groupTitle += ` "${payload.title}"`;
    else groupTitle += ` "${requestSignature}"`;

    console.group(groupTitle);
    if (payload.title) console.debug(requestSignature);
    console.debug(`With the following fake request configuration:`, payload.config);
    console.debug(`Emulating the following mocked response:`, payload);
    console.groupEnd();

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (payload.status >= 400) {
          const error = new AxiosError(payload.statusText, String(payload.status), payload.config);
          reject(error);
        } else resolve(payload);
      }, delay);
    });
  }
}

type Method = "get" | "put" | "post" | "delete";
type URLPattern = string;
// Stub(bed) responses may also contain a network delay to be faked and a title for the logs
export type StubResponse = Partial<AxiosResponse & { delay: number; title?: string }>;
// The Dictionary of stub responses is mapped per url and then per method
// URL pattern > method > static stubbed response OR response factory taking the request object as parameter
// a) Stub dictionary at the URL level. It may or may not contain all Methods
type StubResponseDictionary = Record<URLPattern, Partial<MethodStubDictionary>>;
// b) Stub dictionary at the Method level. added a "defaultStub" field to indicate the stub searched for wasn't found and the default stub was used instead
type MethodStubDictionary = Record<Method | "default", Partial<StubResponse & { defaultStub?: true }> | StubFactory> & {
  // redirect stubs may either replace the redirection url or cancel the redirection by returning null
  redirect: RedirectStub;
};
// c) Stub factory definition.
//   Params: The request object + the matched url as parameter
//   Returns: The stub response object
type StubFactoryParameter = Partial<AxiosRequestConfig> & { urlMatch?: RegExpExecArray | string };
export type StubFactory = (options: StubFactoryParameter) => StubResponse;

type FoundStub<TheMethod extends Method | "redirect"> = {
  stub: StubResponseDictionary[string][TheMethod];
  urlMatch?: RegExpExecArray | string;
};

type RedirectStub = (url: URL) => URL | null;
