import type { ModuleDefinition } from '@module/common/types/oneSDKModules/definition.types';
import type { Branded, Dictionary } from '@types';

import type { BaseParameters } from './baseParameters.types';
import type { ModuleCategory } from './categories';

/**
 * Module Initialise Function
 * Not all modules actually require extra options and to make it explicit, if ExclusiveOptions isn't defined, it defaults to void
 * ModuleInitialiseFunctionParameters will simplify typing the arguments for the initialise function
 */
export type ModuleInitialiseFunction<
  Cat extends ModuleCategory,
  Context extends object = object,
  ExclusiveOptions extends Dictionary<unknown> | void = void,
  InjectedParameters extends object = object,
> = (...args: ModuleInitialiseFunctionParameters<Cat, ExclusiveOptions, InjectedParameters>) => Context;

/**
 * Helper type for the initialise function parameters
 * 1) The first parameter provides global dependencies and depends on the category of the module.
 * TODO: Remove dependency on category all together. All modules should be able to access all global dependencies.
 * TODO: Modules with categories other than "component" and "flow" should not be an OneSDK Module.
 * TODO: Individual Module should be converted to a "component" even though it's special and instantiated internally by the OneSDK.
 * 2) The second parameter provides special custom options, IF that module has any defined.
 **/
export type ModuleInitialiseFunctionParameters<
  Cat extends ModuleCategory,
  CustomOptions,
  InjectedParameters extends object,
> = CustomOptions extends void
  ? [global: BaseParameters<Cat> & InjectedParameters]
  : [global: BaseParameters<Cat> & InjectedParameters, options: CustomOptions];

/**
 * Helper to allow ANY module initialise function to be provided as a parameter
 * Uses all broad type parameters so any valid generic should be acceptable
 **/
export type AnyModuleInitialiseFunction = (global: object, options?: object) => object;

/**
 * Branding is not only used to enforce the initialise function and the key in the ModuleDictionary object are consistent,
 * but also to allow the initialise function itself to carry the whole Module definition with it
 */

export const brandInitialiseFunction = <M extends ModuleDefinition>(
  name: M['moduleName'],
  initialise: M['moduleInitialiseFunction'],
) => Object.assign(initialise, { __definition: name as unknown }) as BrandedInitialiseFunction<M>;

export type BrandedInitialiseFunction<Module extends ModuleDefinition = ModuleDefinition> = Branded<
  Module['moduleInitialiseFunction'],
  '__definition',
  Module
>;

/**
 * Initialisation registry
 */
export type InitialisationDictionary = {
  [k: string]: BrandedInitialiseFunction<ModuleDefinition>;
};

/**
 * Unbranding is used to retrieve the original ModuleDefinition from the initialise function itself (BrandedInitialiseFunction)
 * type Module = ExtractDefinition<typeof initialiseFunction> = ModuleDefinition
 */
export type ExtractDefinition<Initialise> = Initialise extends BrandedInitialiseFunction<
  infer T extends ModuleDefinition
>
  ? T
  : never;
/**
 * Extract a dictionary of module definitions from a dictionary of initialise functions
 */
export type ExtractDefinitions<Dict extends InitialisationDictionary> = {
  [K in keyof Dict]: ExtractDefinition<Dict[K]>;
};
