import { mkVendorLoader } from "@module/common";
import { defineModule } from "@module/common/modules/defineModule";
import { ActivityType } from "./constants";
import { DeviceModule } from "./definition";
import { validateConfiguration } from "./parseConfiguration";

export default defineModule<DeviceModule>("device", (globalState, options) => {
  /**
   * All the oneSdk components expose a synchronous "ModuleContext" object whose properties
   * need to be declared outside the asynchronous "main" function. They are then "passed down" to be used by the main function.
   *
   * This keeps all modules initialisation signatures uniform.
   * The "main" function is assynchronous and will return data in form of events.
   *
   * The set function allows customer to make "late" changes to internal module data (after initialisation options).
   *  Things such as set "username/email/applicant reference" as soon as they are discovered
   * The on and off functions allow customers to listen for internal lifecycle events
   */
  const { globalEventHub, localEventHub } = globalState;
  // Validate configuration or prevent module initialisation by throwing an exception
  validateConfiguration(globalState);

  const loadVendor = mkVendorLoader({
    vendorName: globalState.recipe.deviceCharacteristics.provider.name,
    sharedConfiguration: globalState,
    vendorLoader: {
      sardine: () => import(/* webpackChunkName: 'device-sardine' */ "./vendors/Sardine"),
      threatmetrix: () => import(/* webpackChunkName: 'device-threatmetrix' */ "./vendors/Threatmetrix"),
    },
  });
  /** Define context methods */

  // main will trigger the following procedure
  // - determine vendor from recipe configuration
  // - load vendor sdk (async)
  //    - initialise vendor session (async)
  // - initialise vendor sdk (async)
  //    - provide vendor context object as soon as sdk is initialised.
  // - Vendor context API is the same for all vendors. All the particularities are implemented within the vendor wrapper
  const start = () => {
    loadVendor({
      vendorWrapperOptions: { ...options, eventHub: localEventHub },
      onSuccess: (_, { vendorName }) => {
        globalEventHub.emit("telemetry", {
          eventName: "DEVICE:MOUNT",
          data: { vendor: vendorName },
        });
      },
      onError: (error, { vendorName }) => {
        globalEventHub.emit("telemetry", {
          eventName: "DEVICE:MOUNT:ERROR",
          data: { vendor: vendorName },
          error,
        });
      },
    });
  };
  // const startActivity = async (activityType: ActivityType) => {
  //   vendorContext.set("activityType", activityType);
  // };

  /** reemit/map/transform events below */

  // value_set: when the value for a field is confirmed by the vendor wrapper to be set
  localEventHub.on("value_set", ({ key, value }) => {
    //  When activityType is set, emit another more specific event "activity_started"
    if (key === "activityType") localEventHub.emit("activity_started", { activityType: <ActivityType>value });
  });

  return {
    start,
  };
});

export * from "./constants";
export * from "./definition";
export * from "./events";
