// TODO -- decouple it from eventHub
// TODO -- instead of automatically running the function on the callback event, it should check the condition again

import type { KeyOf } from '@types';

import type { EventHub, EventsDictionary, ExtendedEvents } from './eventHub';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Runnable = (...args: any[]) => any;
/**
 * Ensures only one level of promises is provided
 */

type BasicOptions<Callback extends Runnable> = {
  condition: () => boolean;
  run: Callback;
};
type OptionsWithCallback<Callback extends Runnable, Events extends EventsDictionary> = BasicOptions<Callback> & {
  elseWaitFor: KeyOf<ExtendedEvents<Events>>;
  on: EventHub<Events>;
  timeoutMilliSecond?: number;
};
type Options<Callback extends Runnable, Events extends EventsDictionary> =
  | BasicOptions<Callback>
  | OptionsWithCallback<Callback, Events>;

/**
 * runIfWhen
 * - runs the function "run" if "condition" is true,
 * - otherwise it will wait for the event "elseWaitFor" to be emitted by "eventHub" to run the function
 * @param run main function to be run
 * @param condition function returning a boolean
 * @param elseWaitFor string with the name of the event to trigger a retry
 *
 * @returns a function that takes the same parameters as "run" and returns a promise with the same results returned by "run"
 */

const hasEventCallback = <CB extends Runnable, EVTS extends EventsDictionary>(
  option: Options<CB, EVTS>,
): option is OptionsWithCallback<CB, EVTS> => 'elseWaitFor' in option;
export function runIfWhen<Callback extends Runnable, Events extends EventsDictionary>(
  opt: Options<Callback, Events>,
): Callback {
  const callback = (...args: unknown[]) => {
    if (opt.condition()) return Promise.resolve(opt.run(...args));
    else if (hasEventCallback(opt)) {
      return new Promise((resolve, reject) => {
        const eventName = opt.elseWaitFor;
        const eventCallback = async () => {
          if (timeout) clearTimeout(timeout);
          resolve(opt.run(...args));
        };
        const timeoutCallback = () => {
          opt.on.off(eventName, eventCallback);
          reject(`Timed out in ${opt.timeoutMilliSecond} milliseconds.`);
        };
        let timeout: ReturnType<typeof setTimeout>;
        if (opt.timeoutMilliSecond) timeout = setTimeout(timeoutCallback);
        opt.on.on(eventName, eventCallback);
      });
    }
  };

  return callback as Callback;
}

/**
 * runOnce
 * - takes a function and an error message and returns an equivalent function that
 * can only be called once. The second time the function is called, an error is thrown
 * containing the provided error message.
 * @param func The function to be run
 * @param errorMessage An error message if the function is called twice
 * @returns The provided function
 */
export const runOnce = <Func extends Runnable>(func: Func, errorMessage: string): Func => {
  let wasCalled = false;
  return ((...args) => {
    if (wasCalled) throw new Error(errorMessage);
    const result = func(...args);
    wasCalled = true;
    return result;
  }) as Func;
};
