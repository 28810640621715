import { env2url, ENVIRONMENTS } from "@module/common";
import { stringToBase64 } from "@module/common/modules/base64";
import { ApplicantId, SessionContext } from "@module/session/SessionContext";
import Axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { ClientsConstructorDictionary, InstanceOf, LoadClientParametersFor } from "./ClientsDictionary";

export type IFrankieClientCredentials = {
  customerID: string;
  customerChildID?: string;
  apiKey: string;
};

export class FrankieApiClient {
  protected instance: AxiosInstance;
  protected sessionContext: SessionContext;

  constructor() {
    this.instance = Axios.create();
    this.interceptors.response.use((response: AxiosResponse) => {
      const token = response.headers.token;
      if (token && this.session) {
        this.session.setToken(token);
        this.instance.defaults.headers.common.authorization = token;
      }
      return response;
    });
  }
  get interceptors(): AxiosInstance["interceptors"] {
    return this.instance.interceptors;
  }

  set entityId(value: string) {
    this.sessionContext.setEntityId(value);
  }

  async login(
    credentials: IFrankieClientCredentials,
    applicantId: ApplicantId,
    options: { environment: ENVIRONMENTS; preset?: string }
  ): Promise<string> {
    const endpoint = "/auth/v2/machine-session";
    const agent = "machine";
    const environmentUrl = env2url(options.environment);
    this.instance.defaults.baseURL = environmentUrl;

    const response = await this.post<{ token: string }>(
      endpoint,
      {
        permissions: {
          preset: options.preset ?? "one-sdk",
          ...applicantId,
        },
      },
      {
        headers: { authorization: `${agent} ${serialiseCredentials(credentials)}` },
      }
    );
    const { token } = response.data;
    if (!token) throw new Error("Missing token");
    this.session = new SessionContext(token, { takenFromStorage: false });

    const tokenEnv = new URL(this.session.environment).toString();
    const providedEnv = new URL(environmentUrl).toString();
    // eslint-disable-next-line no-console
    if (tokenEnv !== providedEnv) console.warn(`Environment mismatch: Provided '${providedEnv}' (${options.environment}), but token is '${tokenEnv}`);
    return token;
  }

  get token(): string {
    return this.session.token;
  }

  get session(): SessionContext {
    return this.sessionContext;
  }

  set session(session: SessionContext) {
    this.instance.defaults.baseURL = session.environment;
    this.instance.defaults.headers.common.authorization = session.token;
    this.instance.defaults.headers.common["X-Frankie-Channel"] = "onesdk";

    this.sessionContext = session;
  }

  validateSession() {
    return this.get("data/v2/token-validity");
  }

  get<T = unknown>(url: string, config?: AxiosRequestConfig | undefined): Promise<AxiosResponse<T>> {
    return this.instance.get(url, config);
  }
  delete<T = unknown>(url: string, config?: AxiosRequestConfig | undefined): Promise<AxiosResponse<T>> {
    return this.instance.delete(url, config);
  }
  post<T = unknown>(url: string, data?: unknown, config?: AxiosRequestConfig | undefined): Promise<AxiosResponse<T>> {
    return this.instance.post(url, data, config);
  }
  put<T = unknown>(url: string, data?: unknown, config?: AxiosRequestConfig | undefined): Promise<AxiosResponse<T>> {
    return this.instance.put(url, data, config);
  }
  redirect(url: URL) {
    window.location.href = url.href;
  }

  async loadClient<Client extends keyof ClientsConstructorDictionary>(
    ...parameters: LoadClientParametersFor<Client>
  ): Promise<InstanceOf<Client>> {
    const [which, options] = parameters;

    let ClientClass; /** TODO: Add type to this variable*/
    if (which === "configuration") ClientClass = (await import("./clients/ConfigurationClient")).ConfigurationClient;
    else if (which === "applicant") ClientClass = (await import("./clients/ApplicantClient")).ApplicantClient;
    else if (which === "telemetry")
      ClientClass = (await import("./clients/TelemetryEventsClient")).TelemetryEventsClient;

    return new ClientClass(this, options) as InstanceOf<Client>;
  }
}

function serialiseCredentials(credentials: IFrankieClientCredentials): string {
  const elements = [credentials.customerID, credentials.customerChildID, credentials.apiKey];
  return stringToBase64(elements.filter(Boolean).join(":"));
}
