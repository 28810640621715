import type { Accessors } from '@module/common';
import type { Address } from '@module/common/shared/models/Address';

import type { IndividualModule } from '../definition';

type IndividualModuleContext = IndividualModule['moduleContext'];
type MkAddDocumentOptions = {
  addresses$: Accessors<Address[]>;
};
export const mkAddAddress =
  (deps: MkAddDocumentOptions): IndividualModuleContext['addAddress'] =>
  (address) => {
    const { addresses$ } = deps;
    const allAddresses = addresses$.getValue();
    allAddresses.push(address);
    addresses$.setValue(allAddresses);
  };

export const mkUpdateAddress =
  (deps: MkAddDocumentOptions): IndividualModuleContext['updateAddress'] =>
  (id, address) => {
    const { addresses$ } = deps;
    const allAddresses = addresses$.getValue();
    const index = allAddresses.findIndex((d) => d.addressId === id);

    address.addressId = id;

    if (index === -1) {
      throw new Error(`Address with id ${id} not found`);
    } else {
      allAddresses[index] = address;
      addresses$.setValue(allAddresses);
    }
  };
