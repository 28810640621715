import { InjectedState } from "@module/common/types";
import { SdkModes } from "@module/sdk/types";

export function validateConfiguration(shared: InjectedState) {
  const { recipe } = shared;

  if (shared.mode.modeName === SdkModes.DUMMY) {
    throw new Error("There is no implementation for the device component in 'dummy' mode.");
  }
  if (!recipe.deviceCharacteristics?.provider?.name) {
    throw new Error(
      "Your account doesn't have any device characteristics providers configured. Please contact FrankieOne support."
    );
  }
  if (!recipe.deviceCharacteristics?.provider?.environment) {
    throw new Error(
      `Your account is missing the environment configuration for the device characteristics provider "${recipe.deviceCharacteristics.provider.name}". Please contact FrankieOne support.`
    );
  }
}
