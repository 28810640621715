import merge from "lodash.merge";
import { Address } from "@module/common/shared/models/Address";
import { Applicant } from "@module/common/shared/models/Applicant";
import { Document } from "@module/common/shared/models/Document";
import type { DeepPartial } from "@module/common/shared/models/general";
import { v4 as uuid } from "uuid";

export type ResolveIndividualDataOptions = DeepPartial<ResolvedIndividualData>;
export type ResolvedIndividualData = {
  individual: Applicant;
  documents: Document[];
};
export function resolveIndividualData(options: ResolveIndividualDataOptions): ResolvedIndividualData {
  const resolveAddress = (address: DeepPartial<Address>): Address => Object.assign(new Address(), address);
  return {
    individual: merge(
      new Applicant(),
      {
        name: { givenName: "John", familyName: "Doe", middleName: null },
        dateOfBirth: "1991-10-16",
        entityId: `mockpreloaded-${uuid()}`,
        addresses: (options.individual?.addresses ?? []).map(resolveAddress),
      },
      options.individual ?? {}
    ),
    documents: options.documents?.map((docConfig) =>
      merge(
        new Document(),
        {
          documentId: `mockpreloaded-${uuid()}`,
          idNumber: String(Math.floor(Math.random() * 999999999)),
          country: "AUS",
          idType: "OTHER",
          ocrResult: {},
        } as Partial<Document>,
        docConfig
      )
    ),
  };
}