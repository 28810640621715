import allAustralianStates from "../data/aus_states.json";
import allCountries from "../data/country.json";
type TCountryObject = { label: string };
export function getCountryDetails(code3: string): TCountryObject {
  const country = allCountries.find((c) => c.alpha3Code === code3);
  const countryObject = { label: country?.name ?? code3 };
  return countryObject;
}
type TStateObject = { label: string };
export function getAustralianStateDetails(code3: string): TStateObject | null {
  const state = allAustralianStates.find((s) => s.alpha3Code === code3);
  const stateObject = { label: state?.name ?? code3 };
  return stateObject;
}
