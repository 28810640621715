import { v4 as uuid } from "uuid";
import { DummyFrankieApiClient, URL_PARAM } from "../../DummyFrankieApiClient";
export function submitSuccessfulData(client: DummyFrankieApiClient): DummyFrankieApiClient {
  client.stubResponse(
    { url: "/data/v2/applicants", method: "post" },
    {
      title: "Create individual",
      data: {
        entityId: uuid(),
      },
    }
  );
  client.stubResponse({ url: new RegExp(`data/v2/applicants/${URL_PARAM}`), method: "put" }, (config) => {
    return {
      title: "Update individual",
      data: {
        entityId: config.urlMatch[1],
      },
    };
  });
  return client;
}
export function successfulVerification(client: DummyFrankieApiClient): DummyFrankieApiClient {
  client.stubResponse(
    { url: new RegExp(`data/v1/applicants/${URL_PARAM}/checks`), method: "post" },
    {
      title: "Trigger a successful verification",
      status: 200,
      data: {},
    }
  );
  return client;
}
