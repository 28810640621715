import { SessionContext } from "./SessionContext";

/**
 * SessionFacade simply exposes the session module's public API. Nothing that allows customers to trick our service into accepting requests it's not supposed to
 */
export class SessionFacade {
  constructor(private readonly sessionContext: SessionContext) {}

  getEntityId(): string | null {
    return this.sessionContext.entityId;
  }
  getReference(): string | null {
    return this.sessionContext.reference;
  }
  getSessionId(): string {
    return this.sessionContext.sessionId;
  }
  isPersisted(): boolean {
    return this.sessionContext.isTakenFromStorage();
  }
  unpersist(): void {
    return this.sessionContext.unpersist();
  }
}
