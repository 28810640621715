import { Address } from "../models/Address";

export const isEmptyAddress = (address: Address): boolean => {
  const { country, suburb, town, streetName } = address;
  const isEmpty = (requireList) => requireList.some((field) => !address[field]);

  switch (country) {
    case "AUS": {
      const suburbOrTown = Boolean(suburb || town);
      return !suburbOrTown || !["streetName", "state", "country", "postalCode"].every((f) => Boolean(address[f]));
    }
    case "NZL":
      return isEmpty(["streetName", "postalCode"]);
    case "THA":
      return isEmpty(["streetName", "town"]);
    case "IDN":
      return isEmpty(["streetName", "town"]);
    case "SGP":
      return isEmpty(["streetName", "postalCode", "streetNumber"]);
    default:
      return !((suburb || town || streetName) && country);
  }
};
