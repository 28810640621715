import { Document, IDocumentPayload } from "@module/common/shared/models/Document";
import type { FrankieApiClient } from "../FrankieApiClient";
import { BaseClient } from "./BaseClient";

export class OCRClient extends BaseClient {
  constructor(frankie: FrankieApiClient) {
    super(frankie);
  }
  async attachNewOCRDocument(file?: File): Promise<OCRResults> {
    const formData = new FormData();
    if (file) {
      formData.append("image-file", file);
    }
    return this.frankie
      .post<OCRResultsResponse>(`data/v2/idvCheck/${this.entityId}/ocr`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const { ocrDocument, status: documentStatus } = response.data;

        return {
          ocrDocument: Document.fromJSON(ocrDocument),
          documentStatus,
        };
      });
  }
  async rerun(documentId: string): Promise<OCRResults> {
    return this.frankie
      .put<OCRResultsResponse>(
        `data/v2/idvCheck/${this.entityId}/ocr`,
        {
          documentId,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        const { ocrDocument, status: documentStatus } = response.data;

        return {
          ocrDocument: Document.fromJSON(ocrDocument),
          documentStatus,
        };
      });
  }
  async updateOCRDocument(file: File, documentId: string, scanId?: string): Promise<OCRResults> {
    const formData = new FormData();
    formData.append("image-file", file);
    formData.append("documentId", documentId);
    if (scanId) formData.append("scanId", scanId);

    return this.frankie
      .put<OCRResultsResponse>(`data/v2/idvCheck/${this.entityId}/ocr`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const { ocrDocument, status: documentStatus } = response.data;
        return {
          ocrDocument: Document.fromJSON(ocrDocument),
          documentStatus,
        };
      });
  }
}

export type OCRResults = {
  ocrDocument: Document;
  documentStatus: OCRStatus;
};
type OCRResultsResponse = {
  ocrDocument: IDocumentPayload;
  status: OCRStatus;
};

// {
//   "AWAITING_DOCUMENT_OCR", # # onesdk has captured a document but ocr process is yet to run. Default status if we get errors and user abandons process
//   "AWAITING_DOCUMENT_UPLOAD_FRONT", # onesdk needs to capture front image
//   "AWAITING_DOCUMENT_UPLOAD_BACK", # onesdk needs to capture back image
//   "COMPLETE_OCR", # onesdk has captured a front and/or back image, submitted successfully, and ocr results are available
//   "AWAITING_DOCUMENT_UPLOAD_FAILED_OCR", # onesdk has captured a document but during upload an error was given by the provider
//   "AWAITING_DOCUMENT_UPLOAD_DOCUMENT_INVALID_TYPE", # onesdk has captured a front image and submitted successfully but documenttype is invalid
//   "AWAITING_OCR_RESULTS_PROVIDER_OFFLINE", # onesdk has captured a document but there was an issue uploading the document or retrieving the result
//   "DOCUMENT_INVALID_EXCEEDED_SIZE_LIMIT", # onesdk has captured a front image but failed validation due to file size exceeding the limit
//   "DOCUMENT_INVALID_INCORRECT_FILE_FORMAT", # onesdk has captured a front image but failed validation due to incorrect file format
//  }
export enum OCRStatus {
  WAITING_OCR_RUN = "AWAITING_DOCUMENT_OCR",
  WAITING_BACK = "AWAITING_DOCUMENT_UPLOAD_BACK",
  WAITING_FRONT = "AWAITING_DOCUMENT_UPLOAD_FRONT",
  COMPLETE = "COMPLETE_OCR",
  DOCUMENTS_INVALID = "AWAITING_DOCUMENT_UPLOAD_INVALID_TYPE",
  DOCUMENTS_UPLOAD_FAILED = "AWAITING_DOCUMENT_UPLOAD_FAILED_OCR",
  PROVIDER_OFFLINE = "AWAITING_OCR_RESULTS_PROVIDER_OFFLINE",
  FAILED_FILE_SIZE = "DOCUMENT_INVALID_EXCEEDED_SIZE_LIMIT",
  FAILED_FILE_FORMAT = "DOCUMENT_INVALID_INCORRECT_FILE_FORMAT",
  INTERRUPTED = "INTERRUPTED",
}
export type FailedStatus =
  | OCRStatus.DOCUMENTS_UPLOAD_FAILED
  | OCRStatus.DOCUMENTS_INVALID
  | OCRStatus.FAILED_FILE_FORMAT
  | OCRStatus.FAILED_FILE_SIZE
  | OCRStatus.INTERRUPTED;
export type CompleteStatus = OCRStatus.COMPLETE;
export type InputRequiredStatus = OCRStatus.WAITING_BACK | OCRStatus.WAITING_FRONT;

export const isCompleteStatus = (status: OCRStatus): status is CompleteStatus => {
  return status === OCRStatus.COMPLETE;
};
export const isInputRequiredStatus = (status: OCRStatus): status is InputRequiredStatus => {
  return [OCRStatus.WAITING_BACK, OCRStatus.WAITING_FRONT].includes(status);
};
export const isFailedStatus = (status: OCRStatus): status is FailedStatus => {
  return [
    OCRStatus.DOCUMENTS_UPLOAD_FAILED,
    OCRStatus.DOCUMENTS_INVALID,
    OCRStatus.FAILED_FILE_FORMAT,
    OCRStatus.FAILED_FILE_SIZE,
    OCRStatus.INTERRUPTED,
  ].includes(status);
};
export type OCRDocumentType = "DRIVERS_LICENCE" | "PASSPORT";
