import { IDVStatus } from "@module/frankie-client/clients/IDVClient";
import { OCRStatus } from "@module/frankie-client/clients/OCRClient";

import type { SimplifiedMockOptions } from "./SimplifiedMockOptions.type";

export const defaultMockOptions: SimplifiedMockOptions = {
  preloadedIndividual: {
    documents: [{ idType: "PASSPORT", scans: "F" }],
  },
  ocrFlow: {
    detectedType: "DRIVERS_LICENCE",
    statusResults: [OCRStatus.WAITING_BACK, OCRStatus.DOCUMENTS_INVALID, OCRStatus.COMPLETE],
  },
  idvFlow: {
    result: IDVStatus.COMPLETE,
  },
};
