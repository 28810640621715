import { DuplicateResults } from "./Duplicate";
import { Nullable } from "./general";
import { IIssue, IIssueAction } from "./TApplicantIssue";
import { TApplicantStatus } from "./TApplicantStatus";
import { TKycMethod } from "./TKycMethod";
import { TValidation } from "./TValidation";
type issue = {
  code: string | null;
  description: string | null;
};

type TMatchsetCount = {
  required: number | null;
  actual: number | null;
};

export type TRulesetMatchFields = {
  name: TMatchsetCount;
  dob: TMatchsetCount;
  address: TMatchsetCount;
  govId: TMatchsetCount;
};
interface IConstructorCheckResult {
  type: string | null;
  result: TApplicantStatus;
  issues: issue[];
}

interface IConstructorRulesetsCount {
  name: string | null;
  order: number | null;
  result: string | null;
  rulesetMatchFields: TRulesetMatchFields;
}
type CheckSource = string[] | null;

export class CheckResult {
  type: string | null;
  result = {
    key: "",
    type: null,
    label: "",
  } as TApplicantStatus;
  issues: issue[];
  constructor(payload: IConstructorCheckResult) {
    this.type = payload.type || null;
    this.result.type = payload?.result?.type || null;
    this.result.label = payload.result.label || "";
    this.result.action = payload.result.action;
    this.issues = payload.issues || [];
  }
}
export interface ICheckSummaryPayload {
  checkTypes: string[];
  checkDate: string;
  personalChecks: {
    name: TValidation;
    dateOfBirth: TValidation;
    phoneNumber: TValidation;
    email: TValidation;
    chineseName: Nullable<string>;
    addresses: {
      [k: string]: TValidation;
    };
  };
  status: TApplicantStatus;
  blocklistPotentialMatches: DuplicateResults[];
  duplicatePotentialMatches: DuplicateResults[];
  duplicateBlocklistMatches: DuplicateResults[];
  risk: {
    level: number | null;
    class: string | null;
  };
  alertList: (IIssue | IIssueAction)[];
  checkCounts: {
    name: CheckSource;
    address: {
      [k: string]: CheckSource;
    };
    document: {
      [k: string]: CheckSource;
    };
    dob: CheckSource;
  };
  kycMethod: TKycMethod;
  issues: { [issue: string]: any };
  checkResults?: CheckResult[];
  rulesetsMatches: RulesetsMatch[];
}

export interface ICheckSummaryConstructor {
  checkTypes: string[];
  checkDate: string;
  personalChecks: {
    name: TValidation;
    dateOfBirth: TValidation;
    phoneNumber: TValidation;
    email: TValidation;
    chineseName: Nullable<string>;
    addresses: {
      [k: string]: TValidation;
    };
  };
  status: string;
  blocklistPotentialMatches: DuplicateResults[];
  duplicatePotentialMatches: DuplicateResults[];
  duplicateBlocklistMatches: DuplicateResults[];
  risk: {
    level: number | null;
    class: string | null;
  };
  alertList: (IIssue | IIssueAction)[];
  checkCounts: {
    name: CheckSource;
    address: {
      [k: string]: CheckSource;
    };
    document: {
      [k: string]: CheckSource;
    };
    dob: CheckSource;
  };
  kycMethod: TKycMethod;
  issues: { [issue: string]: any };
  checkResults?: CheckResult[];
  rulesetsMatches?: RulesetsMatch[];
}

const numericOrNull = (v: any): number | null => (!isNaN(v) ? v : null);
const booleanOrNull = (v: any): boolean | null => (typeof v === "boolean" ? v : null);
const stringOrNull = (v: any): string | null => (typeof v === "string" ? v : null);
const buildArrayWith =
  <V>(visit: (v: V) => V) =>
  (carry, [key, value]) => {
    carry[key] = visit(value);
    return carry;
  };
const visitObjectWith =
  (addrs) =>
  <V>(visitor: (v: V) => V) => {
    const entries = Object.entries(addrs);
    const rebuilt = entries.reduce(buildArrayWith(visitor), {});
    return rebuilt;
  };
const buildAddressValidationObject = (addrs) => visitObjectWith(addrs)(booleanOrNull);
// const buildAddressCountObject = (addrs) => visitObjectWith(addrs)(numericOrNull);

export class RulesetsMatch {
  name: string | null;
  order: number | null;
  result: string | null;
  rulesetMatchFields: TRulesetMatchFields;
  constructor(payload: IConstructorRulesetsCount) {
    this.name = payload.name || null;
    this.order = payload.order || null;
    this.result = payload?.result || null;
    this.rulesetMatchFields = payload.rulesetMatchFields || null;
  }
}

export class CheckSummary implements ICheckSummaryPayload {
  checkDate: string;
  checkTypes: string[];
  personalChecks: {
    name: TValidation;
    dateOfBirth: TValidation;
    phoneNumber: TValidation;
    email: TValidation;
    chineseName: Nullable<string>;
    addresses: {
      [k: string]: TValidation;
    };
  };
  status: TApplicantStatus;
  risk: {
    level: number | null;
    class: string | null;
  };
  alertList: (IIssue | IIssueAction)[];
  blocklistPotentialMatches: DuplicateResults[];
  duplicatePotentialMatches: DuplicateResults[];
  duplicateBlocklistMatches: DuplicateResults[];
  checkCounts: {
    name: CheckSource;
    address: {
      [k: string]: CheckSource;
    };
    document: {
      [k: string]: CheckSource;
    };
    dob: CheckSource;
  };
  kycMethod: TKycMethod;
  issues: { [issue: string]: any };
  checkResults: CheckResult[];
  rulesetsMatches: RulesetsMatch[];

  constructor(c: ICheckSummaryConstructor) {
    this.checkTypes = c.checkTypes || [];
    this.checkDate = c.checkDate || "";
    this.personalChecks = {
      name: booleanOrNull(c.personalChecks.name),
      dateOfBirth: booleanOrNull(c.personalChecks.dateOfBirth),
      phoneNumber: booleanOrNull(c.personalChecks.phoneNumber),
      email: booleanOrNull(c.personalChecks.email),
      chineseName: stringOrNull(c.personalChecks.chineseName),
      addresses: buildAddressValidationObject(c.personalChecks.addresses),
    };
    this.status = mapBackendStatus(c.status || "UNCHECKED");
    this.blocklistPotentialMatches = c.blocklistPotentialMatches ?? [];
    this.duplicatePotentialMatches = c.duplicatePotentialMatches ?? [];
    this.duplicateBlocklistMatches = c.duplicateBlocklistMatches ?? [];
    this.risk = {
      level: numericOrNull(c.risk.level), // using fallback assignment doesnt work for 0
      class: c.risk.class || null,
    };
    this.alertList = c.alertList || [];
    this.checkCounts = {
      name: c.checkCounts.name,
      address: c.checkCounts.address,
      document: c.checkCounts.document,
      dob: c.checkCounts.dob,
    };
    this.kycMethod = c.kycMethod;
    this.issues = c.issues || {};
    this.checkResults = c.checkResults || [];
    this.rulesetsMatches = c.rulesetsMatches || [];
  }

  toJSON(): ICheckSummaryPayload {
    return { ...this };
  }

  clone(): CheckSummary {
    const payload = JSON.parse(JSON.stringify(this));
    const checkSummary = CheckSummary.fromJSON(payload);
    return checkSummary;
  }

  static fromJSON(payload: ICheckSummaryPayload): CheckSummary {
    return new CheckSummary({
      checkDate: payload.checkDate,
      checkTypes: payload.checkTypes,
      personalChecks: payload.personalChecks,
      status: payload.status.key,
      alertList: payload.alertList,
      blocklistPotentialMatches: payload.blocklistPotentialMatches,
      duplicatePotentialMatches: payload.duplicatePotentialMatches,
      duplicateBlocklistMatches: payload.duplicateBlocklistMatches,
      kycMethod: payload.kycMethod,
      issues: payload.issues,
      risk: payload.risk,
      checkCounts: payload.checkCounts,
      checkResults: payload.checkResults,
      rulesetsMatches: payload.rulesetsMatches,
    });
  }
  static default(): CheckSummary {
    return CheckSummary.fromJSON({
      checkDate: "",
      checkTypes: [],
      personalChecks: {
        name: null,
        dateOfBirth: null,
        phoneNumber: null,
        email: null,
        chineseName: "",
        addresses: {},
      },
      status: {
        type: null,
        label: "",
        key: "",
      },
      alertList: [],
      blocklistPotentialMatches: [],
      duplicatePotentialMatches: [],
      duplicateBlocklistMatches: [],
      kycMethod: "electronic",
      issues: {},
      risk: {
        level: null,
        class: null,
      },
      checkCounts: {
        address: {},
        dob: null,
        document: {},
        name: null,
      },
      rulesetsMatches: [],
    });
  }
}

export const STATE_FAILED = "failed";
export const STATE_PASSED = "passed";
export const STATE_FAIL = "fail";
export const STATE_PASS = "pass";
export const STATE_MANUAL_FAILED = "fail_manual";
export const STATE_MANUAL_PASSED = "pass_manual";
export const STATE_NEEDS_ATTENTION = "refer";
export const STATE_WAITING_ON_CUSTOMER = "wait";
export const STATE_UNCHECKED = "unchecked";

export enum STATE_TYPES {
  FAILED = "failed",
  PASSED = "passed",
  MANUAL_FAILED = "fail_manual",
  MANUAL_PASSED = "pass_manual",
  NEEDS_ATTENTION = "refer",
  WAITING_ON_CUSTOMER = "wait",
  UNCHECKED = "unchecked",
  ARCHIVED = "archived",
  INACTIVE = "inactive",
}

export function mapBackendStatus(state: string): TApplicantStatus {
  const frontend: TApplicantStatus = { type: null, label: "", key: state };

  if (state) {
    state = state.toLowerCase();
    switch (state) {
      case "pass":
      case "passed":
      case "checked_success":
      case "checked_success_clear":
        frontend.type = STATE_TYPES.PASSED;
        frontend.label = "Passed";
        break;
      case "review":
      case "refer":
      case "checked_partial_success":
      case "checked_success_with_notes":
        frontend.type = STATE_TYPES.NEEDS_ATTENTION;
        frontend.label = "Refer";
        break;
      case "no_match":
      case "fail":
      case "failed":
      case "check_stopped":
      case "checked_failed":
      case "not_supported":
      case "unprocessable":
      case "checking":
        frontend.type = STATE_TYPES.FAILED;
        frontend.label = "Failed";
        break;
      case "wait":
      case "waiting":
        frontend.type = STATE_TYPES.WAITING_ON_CUSTOMER;
        frontend.label = "Waiting";
        break;
      case "fail_manual":
      case "manual_fail":
        frontend.type = STATE_TYPES.MANUAL_FAILED;
        frontend.label = "Manually Failed";
        break;
      case "pass_manual":
      case "manual_pass":
        frontend.type = STATE_TYPES.MANUAL_PASSED;
        frontend.label = "Manually Failed";
        break;
      case "archived":
        frontend.type = STATE_TYPES.ARCHIVED;
        frontend.label = "Archived";
        break;
      case "inactive":
        frontend.type = STATE_TYPES.INACTIVE;
        frontend.label = "Inactive";
        break;
      case "unchecked":
        frontend.type = STATE_TYPES.UNCHECKED;
        frontend.label = "Unchecked";
    }
  }

  return frontend;
}

export const sortStatus = (a: TApplicantStatus, b: TApplicantStatus): number => {
  const STATUS_SORT = {
    [STATE_TYPES.PASSED]: 1,
    [STATE_TYPES.MANUAL_PASSED]: 2,
    [STATE_TYPES.NEEDS_ATTENTION]: 3,
    [STATE_TYPES.FAILED]: 4,
    [STATE_TYPES.MANUAL_FAILED]: 5,
    [STATE_TYPES.WAITING_ON_CUSTOMER]: 6,
    [STATE_TYPES.UNCHECKED]: 7,
    [STATE_TYPES.ARCHIVED]: 8,
    [STATE_TYPES.INACTIVE]: 9,
  };

  const aType = a.type;
  const bType = b.type;
  const aPoints = aType ? STATUS_SORT[aType] : 0;
  const bPoints = bType ? STATUS_SORT[bType] : 0;

  return aPoints - bPoints;
};
